@import '../../../styles/custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.post-col {
   max-width: 700px;
   margin: 0 auto;
}
.form-inner{
  max-width: 500px;
  margin: 0 auto;
  padding:20px 0;
}
form#payment-form {
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.06);
  @include media-breakpoint-down(sm){
    padding: 15px;
  } 
  @include media-breakpoint-up(md){
    padding: 30px;
  } 
  background: #fff;
  border
  input {
    border-radius: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  
  .result-message a {
    color:$primary;
    font-weight: 600;
    text-decoration: none;
  }
  
  .hidden {
    display: none;
  }
  
  
  
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    margin: 0 0 10px;
  }
  
  #payment-request-button {
    margin-bottom: 32px;
  }
  
  /* Buttons and links */
  button {
    background:$primary;
    color: #ffffff;
    border-radius: 6px;
    margin: 0 auto;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin: 20px 0 0 ;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background:$primary;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: $primary;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  
}