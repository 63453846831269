@import 'custom-variable/variables';
@import 'plugin/_fonts';
@import 'plugin/icomoon';
@import 'bootstrap';
@import 'custom/header';
@import 'custom/footer';
@import 'react-redux-toastr/src/styles/index';
@import 'toastr';
@import 'bid-history';
@import 'plugin/masonry';
body {
  @include media-breakpoint-down(md) {
    padding-bottom: 80px;
  }
}

@each $name, $color in $grays {
  .text-gray-#{$name} {
    color: $color;
  }
  .bg-gray-#{$name} {
    background-color: $color;
  }
  .border-gray-#{$name} {
    border-color: $color !important;
  }
}

$fontsize: (
  'xxxs': 10px,
  'xxs': 11px,
  'xs': 12px,
  'sm': 13px,
  'normal': 14px,
  'md': 15px,
  'base': rem(16),
  'lg': rem(18),
  'xl': rem(20),
  'xxl': rem(22),
  'xxxl': rem(24),
);

@each $name, $size in $fontsize {
  .font-#{$name} {
    font-size: $size !important;
  }
}

.img-fluid {
  @include img-fluid();
}
.font-weight-semibold {
  font-weight: $font-weight-semibold;
}
.text-underline {
  text-decoration: underline;
  &a:hover {
    text-decoration: none;
  }
}
.line-height-sm{
  line-height: 1;
}
.line-height-md {
  line-height: 1.5;
}
.text-dark {
  color: #1e1d1d !important;
}

.cursor-pointer {
  cursor: pointer;
}

.gutter {
  @include media-breakpoint-down(md) {
  &-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;

      & > [class^='col'] {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
  &-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;

    & > [class^='col'] {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
  &-7 {
    margin-left: -7px !important;
    margin-right: -7px !important;

    & > [class^='col'] {
      padding-left: 7px !important;
      padding-right: 7px !important;
    }
  }
  &-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;

    & > [class^='col'] {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  &-12 {
    margin-left: -12px !important;
    margin-right: -12px !important;

    & > [class^='col'] {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.col-1-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-xl-1-5 {
  @include media-breakpoint-up(xl) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.col-lg-1-5 {
  @include media-breakpoint-up(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.img-cover {
  object-fit: cover;
}

.verified-product {
  i {
    position: relative;
    z-index: 1;
    background-color: $white;
    border-radius: 50%;
  }
  span {
    height: 13px;
    display: inline-block;
    left: -3px;
    position: relative;
    top: 1px;
    z-index: 0;
    padding: 0 5px;
    border-radius: 0 3px 3px 0;
  }
}

.top {
  &-0 {
    top: 0;
  }
  &-100 {
    top: 100%;
  }
}
.bottom {
  &-0 {
    bottom: 0;
  }
  &-100 {
    bottom: 100%;
  }
}
.left {
  &-0 {
    left: 0;
  }
  &-100 {
    left: 100%;
  }
}
.right {
  &-0 {
    right: 0;
  }
  &-100 {
    right: 100%;
  }
}

.breadcrumb-item {
  a {
    color: $gray-500;
  }
}

.w-12-5 {
  width: 12.5%;
}

.border-width-1 {
  border-width: 1px;
}

.slick-arrow {
  width: 47px;
  height: 47px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  &-prev {
    left: -15px;
  }
  &-next {
    right: -15px;
  }
}

.position-revert {
  position: revert;
}

.ReactModal__Body--open{
  overflow: hidden;
  .ReactModal__Content{
    position: relative !important;
  }
  .ReactModal__Overlay{
    z-index: 1001;
    background-color:rgba(0, 0, 0, 0.5) !important;
  }
}
.input-group:focus-within .input-group-text {
  border-color: #ff6262;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(225, 0, 0,.25);
}
.input-group{
  .border-left-0.form-control:focus{
    box-shadow:1px 0 0 1px rgba(225, 0, 0,.25);
  }
}
header{
  @include media-breakpoint-down(md) {
    &.hide-header{
      display: block;
      .header-top{
        padding: 0;
      }
       .header-top>.container>.row> div:not(.searchBox--outer), .header-bottom{
        display: none!important;
      }
    }
  }
}
.error{
  display: block;
  color: #ff0000;
  font-size: 13px;
  margin-top: 3px;
}
.focus-inacive{
  &:focus{
    box-shadow: none;
    outline: 0;
  } 
}
.border-fade{
  border-color:#efefef !important;
}

.option-menu {
  z-index: 1001;
}
.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}
.lg-shadow{
  @include media-breakpoint-up(lg){
    box-shadow: 0px 0px 10px rgba(0, 0, 0,.08) !important;
    background: #fff;
  }
}
.overflow-wrap{
  overflow-wrap: break-word;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left:1px;
  padding: 0;
  background-color: #ffffff!important;
  border: 0!important;
  border-radius: 3px 0 0 3px!important;
}
.react-tel-input .form-control{
  background-color: #ffffff!important;
  border: 1px solid #efefef!important;
  &:focus {
      color: #4e4d4d;
      border-color: #ff6262;
      outline: 0;
      box-shadow: 0 0 0 1px rgba(225, 0, 0,.75);
  }
}
.react-tel-input.form-control{
  box-shadow: none;
  border:0!important;
}