@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'custom/product-box';
@import 'custom/checkbox-radio';
@import 'custom/avatar';

header {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.product-image-block{
  position: relative;
  .slider-count{
    background: $gray-900;
    border-radius: 3px;  
    position:absolute;
    right:15px;
    bottom:15px;
    color: $white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    min-width: 70px;
  }
  background: $white;
  @include media-breakpoint-up(lg) {
    border-radius: 6px 0 0 6px;
  }

}
.detail-image-list {
  li {
    &:focus {
      outline: 0;
    }
  }
  .slick-track  {
    display: flex !important;
  }

  .slick-slide{
    height: inherit !important;
    width: 100% !important;
    >div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      img{
        min-height: 300px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    max-height:615px;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
        img {
          max-height: 615px;
          width: auto;
          max-width: 100%;
          margin: 0 auto;
        }
        .slick-track{
            display: flex !important;
        }

        .slick-slide{
            height: inherit !important;
        }
     
  }
  @include media-breakpoint-down(md) {
    .slick-slide {
      max-height: 305px;
      img{
        max-height: 305px;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  .slick-arrow{
    background-color: rgba($black, 0.5);
    border-radius: 50%;
    display: flex!important;
    align-items: center;
    justify-content: center;
    //margin-top: -2px;
    width: 40px;
    height:40px;
    &:hover,&:focus{
      background-color: rgba($black, 0.5);
    }
    &:before{
      font-family: inherit;
      content: '';
      width:12px;
      height: 12px;
      display: block;
      transform: rotate(45deg);
    }
    &.slick-prev{
      left:20px;
      &:before{
        border-left:2px solid $white;
        border-bottom:2px solid $white;
        margin-left: 4px;
      }
    }
    &.slick-next{
      right:20px;
      &:before{
        border-right:2px solid $white;
        border-top:2px solid $white;
        margin-right: 4px;
      }
    }
  }
  .slick-dots{
    bottom:0;
    button{
      width: 8px;
      height: 8px;
      background-color: rgba($black, 0.5);
      border-radius: 50%;

    }
    .slick-active{
      button{
        background-color: $primary;
      }
    }
    button:before{
      display: none;
    }
  }
}

.main-feature-list {
  li {
    margin-right: 15px;
    width: 109px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.product-description {
  p {
    line-height: rem(22.68);
  }
}


.rating-progress-box {
  max-width: 300px;
  .rating-progress {
    height: 5px;
    .rating-fill {
      border-radius: 2.5px;
      &.rating-5,
      &.rating-4,
      &.rating-3 {
        background-color: #26a541;
      }
      &.rating-2 {
        background-color: #ff9f00;
      }
      &.rating-1 {
        background-color: #ff4343;
      }
    }
  }
}

.user-rating {
  height: 23px;
}
.auction-details{
  .count-down{
    font-size: rem(28);
  }
  .bid-count{
    font-size: rem(28);
  }
}
.auction-buynow{
  figure.listing{
    padding-left:85px ;
    margin: 0;
    min-height: 70px;
    position: relative;
    @include media-breakpoint-down(md){
      &:not(.noresize){
        padding-left: 70px;
      }
      //min-height: 55px;
    }
    .image{
      width: 70px;
      height: 70px;
      border-radius: 5px;
      border:$border-color 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left:0;
      top:0;

       @include media-breakpoint-down(md){
              &:not(.noresize){
        width: 55px;
                height: 55px;
      }
       }
       img{
         max-width: 100%;
         width: auto;
         height: auto;
       }
    }
  }
}

// .slick-dots {
//   display: flex;
//   position: absolute;
//   bottom: rem(20);
//   list-style-type: none;
//   padding: 0;
//   margin: 0;
//   justify-content: center;
//   width: 100%;
//   li {
//     margin-right: 5px;
//     &:last-child {
//       margin-right: 0;
//     }
//     &.slick-active {
//       button {
//         background-color: $white;
//       }
//     }
//     button {
//       font-size: 0;
//       border: 0;
//       background: rgba($white, 0.5);
//       padding: 0;
//       width: 8px;
//       height: 8px;
//       border-radius: 50%;
//       float: left;
//     }
//   }
// }
@include media-breakpoint-up(md) {
  .lg-rounded{
    border-radius: 6px;
  }
}

.view-all-photos {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 $border-radius 0;
}

.related-ads {
  @include media-breakpoint-up(md){
    margin-left: -7px;
  }
  @include media-breakpoint-down(sm){
    margin: 0 -15px;
  }
  .slick-track {
    display: flex !important;
    margin-left: 0;
  }
  .slick-slide {
    height: inherit !important;
    @include media-breakpoint-up(md){
      padding-left: 7px;
      padding-right: 7px;
    }
    @include media-breakpoint-down(sm){
      padding-left: 2px;
      padding-right: 2px;
      .productBox{
        margin-bottom: 4px;
        border-radius: 0!important;
        figure{
          border-radius: 0!important;
        }
      }
    }
    >div{
      display: flex;
      height: 100%;
    }
    &:focus {
      outline: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .slick-arrow{
    background-color:$white;
    border-radius: 50%;
    display: flex!important;
    align-items: center;
    justify-content: center;
    //margin-top: -2px;
    width: 40px;
    height:40px;
    box-shadow: 0 0 3px 3px rgba(0,0,0,0.05);
    
    &:before{
      font-family: inherit;
      content: '';
      width:12px;
      height: 12px;
      display: block;
      transform: rotate(45deg);
    }
    &.slick-prev{
      @include media-breakpoint-up(xl){
        left: -20px;
      }
      @include media-breakpoint-down(lg){
        left: 20px;
      }
      &:before{
        border-left:2px solid $gray-900;
        border-bottom:2px solid $gray-900;
        margin-left: 4px;
      }
    }
    &.slick-next{
      @include media-breakpoint-up(xl){
        right: -20px;
      }
      @include media-breakpoint-down(lg){
        right: 20px;
      }
      &:before{
        border-right:2px solid $gray-900;
        border-top:2px solid $gray-900;
        margin-right: 4px;
      }
    }
  }
}

.product-detail-box {
  @include media-breakpoint-down(md) {
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0,0.08) 
  }
}

.share-popup{
  &:hover{
    .share-popup-list{
      display: block;
    }  
  }
  .share-popup-list{
    padding-top: 10px;
    display: none;
    .share-popup-listinner{
      font-size: rem(25);
      padding: rem(10);
      &::before{
        content: "";
        width: 15px;
        height: 15px;
        background-color: $white;
        transform: rotate(45deg);
        position: absolute;
        right: 10px;
        top: -7px;
      }
    }
  }
}
.enquire-now{
  figure, figcaption{
    max-width:512px;
    width:100%;
  }
  
  .enquire-img{
    text-align: center;
    background: #fff;
    @include media-breakpoint-up(md){
      max-height: 512px;
      border-radius: 6px;
    }
    @include media-breakpoint-down(sm){
      max-height: 300px;
    }

    overflow: hidden;
  }
  .enquire-now-btn{
      width: 180px;
      height: 45px;
  }
  .error-outer{
    padding: 0;
  }
}

