.newsletter-blk{
    height: 45px;
    input{
        &:focus{
            outline: 0;
        }
    }
}

footer{
    @include media-breakpoint-down(md){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 200;
    }
    .#{&}-mob{
        box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
        .container-fluid{
            padding: 0 10px;
        }
        a{
            padding: 11px 0 8px;
            &.active{
                color: $primary;
            }
        }
        i{
            font-size: rem(24);
        }
        span{
            @media(max-width: 375px){
                font-size: 9px !important;
            }
        }
        &__icon{
            height: 24px;
            &--center{
                i{
                    position: absolute;
                    left: 50%;
                    top: 0;
                    width: 52px;
                    height: 52px;
                    transform: translate(-50%, -29px);
                    border: 1px solid $primary;
                    border-radius: 50%;
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}