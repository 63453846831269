.select__multi-value{
    border-radius: 20px!important;
    overflow: hidden;
    &__label{
        padding-left: 12px!important;
    }
    &__remove{
        padding-right: 8px!important;
        cursor: pointer;
    }
}
.select__single-value,.select__placeholder{
    font-size: 14px!important;
}
.Input--gap-md{
    margin-bottom: 0!important;
}
.select__control{
    height: 47px!important;
    &:focus-within,&:focus{
        border-color: #ff6262!important;
        outline: 0;
        box-shadow: 0 0 0 1px rgba(225, 0, 0,.25)!important;
    }
}
.select-menu{
    top:47px!important;
    font-size: 14px!important;
}
.Input{
    font-size: 14px!important;
}