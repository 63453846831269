@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'custom/checkbox-radio';


.post-header{
  @include media-breakpoint-up(lg) {
    min-width: 700px;
    max-width: 700px;
  }
}
.post-product,
.post-product-form {
  .post-col {
    @include media-breakpoint-down(md) {
      padding: 0;
    }  
    @include media-breakpoint-up(lg) {
      min-width: 700px;
      max-width: 700px;
    }
    .breadcrumb-post {
      border-bottom: 0!important;
      margin-top: 0!important;
      padding-bottom: 0!important   ;
      .breadcrumb-item {
        a {
          color: $input-placeholder-color;
        }
        .change {
          line-height: 20px;
        }
      }
    }
  }
  .message{
    border:1px dashed;
    padding:10px 10px 10px 40px;
    border-radius: 6px;
    font-size: 13px;
    &.error{
      background:url(../assets/images/info.png) no-repeat 10px center #FFF0F0;
      border-color:#FFA9A9;
    }
  }
  &-inner {
    box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.06);
    padding: 30px;
    .selected-category{
      @include media-breakpoint-up(lg) {
        margin: 0 -30px;
        padding: 0 30px;
      }
    }
    .line-height-sm{
      line-height: 1;
    }
    @include media-breakpoint-down(md) {
      padding: 15px;
      box-shadow: none;
    }
    .list-category {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
      border: 2px solid transparent;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        border: 2px solid $primary;
      }
      &_selected {
        border: 2px solid $primary;
      }
    }
    .mb-10 {
      margin-bottom: 10px;
    }
  }
  &-inner-form {
    box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.06);
    padding: 40px 0;
    @include media-breakpoint-down(md) {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      margin: 0 -15px;
    }
    
    .block {
      padding: 0 30px;
      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }
    }
  }
}

.uploading-group {
  &-inn {
    height: 120px;
    p {
      opacity: 0;
      transition: 0.3s;
      transform: translateY(7px);
    }
    &:hover {
      p {
        opacity: 1;
        display: block;
        transform: translateY(0px);
      }
      img {
        transform: translateY(0px);
      }
    }
    img {
      transform: translateY(7px);
      transition: 0.3s;
    }
  }
}

.summary-images {
  img {
    height: 120px;
  }
  .main-image {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($primary, 0.4);
  }
}

.switching-checkbox {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 46px;
    height: 26px;
    background-color: $gray-500;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    width: 15px;
    height: 15px;
    background: $white;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + label {
    background-color: $success;
  }
  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 30px;
  }
}
.time-picker{
  .react-datepicker__input-container .form-control{
    background:url(../assets/images/time.png) right center no-repeat #fff!important;
  }
}

.tooltip{
  &-btn{
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  &-pop{
    width: 200px;
    line-height: 16px;
    transform: scale(0.5);
    opacity: 0;
    visibility: hidden;
    transform-origin: left bottom;
    transition: all 0.3s ease-in-out;
  }
  &:hover{
    .tooltip-pop{
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
}

.filter-multiselect{
  .rw-multiselect>.rw-widget-picker, .rw-multiselect .rw-input-reset{
    height: calc(1.5em + 1.5rem + 2px);
  }
  .rw-multiselect>.rw-widget-picker{
    padding-left: 8px;
    box-shadow: none;
    border-color: $border-color;
  }
  .rw-multiselect-taglist{
    vertical-align: middle;
    margin-bottom: calc(0.279335em - 1px);
  }
}