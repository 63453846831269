@import '../../../styles/custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.category-outer{
    @include media-breakpoint-up(lg) {
        border-radius: 6px;
    }
}
.category-selection{
    @include media-breakpoint-down(md) {
       margin:-15px;
       .main-category,.sub-category{
           transition: all ease .5s;
       }
       &.show-sub-category{
            .main-category,.sub-category{
                transform: translate(-100% ,0);
            }
            
        }
    }
    @include media-breakpoint-up(lg) {
        margin:0 -30px -30px;
    }
    
      &_list{
          align-items: center;
          border-top:1px solid $border-color;
          height: 55px;
          cursor: pointer;
          &.main{
              border-right: 1px solid $border-color;
          }
          &.selected{
            background-color: lighten($primary,25%);
            border-color:lighten($primary,25%);
            color: $white;
            .next-arrow-bold{
                border-color: $white;
            }
            
        }
      }
      figure{
          width: 36px;
          text-align: center;
          margin: 0;
      }
      img{
          max-height: 24px;
      }
      .next-arrow-bold{
          border:2px solid #4E4D4D;
          border-width: 2px 2px 0 0;
          width: 8px;
          height: 8px;
          transform: rotate(45deg);
      }
      
}
.createform{
    @include media-breakpoint-up(lg){
        margin: 0 -30px;
        .price-outer{
            margin: 0 -25px;
            padding: 0 30px;
        }
    }
    @include media-breakpoint-down(md){
        margin-bottom: -95px;
        .block {
            margin: -15px 0;
            display: none;
            &.active{
                display: block;
            }
        }
        
        .block .header-mob {
            position: sticky;
            top: 0;
            margin: 0 -15px;
        }
        .main-button{
            position: sticky;
            .btn{
                border-radius: 0!important;
            }
        }
    }
}

.selected-loc{
    padding:0 0 0 23px;
    position: relative;
    &:before{
        width: 15px;
        height: 15px;
        border:1px solid $primary;
        border-radius: 50%;
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
    }
    &:after{
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        border-bottom: 1px solid $primary;
        border-right: 1px solid $primary;
        width: 4px;
        height: 8px;
        transform: rotate(42deg);
    }
}
.map-outer{
    padding:70px 0 20px;
    .form-control.position-absolute{
        top:0;
    }
    .locationselected{
        position: absolute;
        left:4px;
        top:-2px;
        width: 100%;
        &.active{
            z-index: 100;
            background: $white;

        }
    }
}
.Input__field {
    background-color: #fff;
}