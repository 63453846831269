.avatar{
    width: 40px;
    min-width: 40px;
    height: 40px;
    overflow: hidden;
    &--sm{
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    &--md{
        width: 46px;
        min-width: 46px;
        height: 46px;
        border-radius: 50%;
    }
    &--lg{
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    &--xl{
        width: 114px;
        min-width: 114px;
        height: 114px;
        border-radius: 50%;
    }
    // img{
    //     object-fit: cover;
    //     width: 100%;
    //     height: 100%;
    // }
    &--md-square{
        width: 64px;
        min-width: 64px;
        height: 64px;
        border-radius: 6px;
    }
    &--lg-square{
        width: 70px;
        min-width: 70px;
        height: 70px;
        border-radius: 4px;
    }
    &--border{
        border: 3px solid $white;
    }
}
