@import '../../../styles/custom-variable/variables';

.PhoneInput {
  border: 1px solid $input-border-color;
  border-radius: 5px;

  .PhoneInputCountry {
    padding: 0 12px;
  }

  .PhoneInputCountrySelect {
    option {
      box-shadow: none;
      outline: none;
    }
    &:focus + .PhoneInputCountryIcon--border {
      box-shadow: none;
    }
  }

  .PhoneInputCountryIcon {
    border: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    margin-right: 15px;

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .PhoneInputCountryIconImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }

  .PhoneInputCountrySelectArrow {
    width: 8px;
    height: 8px;
    border-right: 2px solid $gray-900;
    border-bottom: 2px solid $gray-900;
    opacity: 0.7;
    margin: 0;
  }

  .Input__field {
    border: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
