@import '../../../styles/custom-variable/variables';
.type-select{
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $border-color;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    height: 53px;
    &__multi-value{
        display: flex;
        margin: 2px 8px;
        background: #e6e6e6;
        border-radius: 20px;
        overflow: hidden;
        &__label{
            border-radius: 2px;
            color: hsl(0,0%,20%);
            font-size: 85%;
            overflow: hidden;
            padding: 3px;
            padding-left: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
        }
        &__remove{
            align-items: center;
            border-radius: 2px;
            display: flex;
            padding-left: 4px;
            padding-right: 8px;
            cursor: pointer;
            &:hover{
                background-color: #FFBDAD;
                path{
                    fill: #DE350B;
                }
            }
        }
    }
    .btn-link{
        font-size: 13px;
        margin-right: 8px;
        border:0;
        box-shadow: none;
        outline: 0;
        &:focus{
            outline: 0;
            box-shadow:none;
        }
    }
}