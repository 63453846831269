@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// tab
.tab {
  .tab-hed {
    @include media-breakpoint-down(md) {
      margin: 0px;
    }
    ul {
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-around;
      }
      li {
        margin-right: 48px;
        @include media-breakpoint-down(md) {
          flex-basis: 0;
          flex-grow: 1;
          text-align: center;
          margin-right: 0;
        }
        .nav-link {
          padding: 0;
          border: none;
          font-size: rem(18);
          color: $text-muted;
          padding: 10px 0px;
          font-weight: 500;
          &.active {
            background-color: transparent;
            border: none;
            color: $primary;
            border-bottom: 2px solid $primary;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
// tab

.chat-section {
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 120px);
  }
  @include media-breakpoint-down(md) {
    overflow: hidden;
    margin-bottom: -80px;
  }
  .avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    max-width: 60px;
    margin-right: 25px;
    position: relative;
    overflow: visible;
    .avatar-main {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 4px;
      background-color: lighten($primary, 50%);
    }
    .avatar-sub {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      bottom: -7px;
      right: -12px;
      background-color: lighten($primary, 50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.chat-listnames {
  @include media-breakpoint-up(lg) {
    // height: calc(100vh - 170px);
    // overflow: auto;
    height: calc(100vh - 270px);
    min-height: 500px;
  }
  @include media-breakpoint-down(md) {
    height: calc(100vh - 100px);
  }
}

.chat-detail-block {
  border-bottom: 1px solid $input-border-color;
  cursor: pointer;
  @include media-breakpoint-down(md) {
    //margin: 0 -15px;
    padding: 0 15px;
    background-color: $white;
    margin-bottom: 5px;
    border-top: 1px solid $input-border-color;
  }
  &:hover,
  &.active {
    background-color: $bg-light;
  }
  .inner-det-block {
    .chat-date {
      position: absolute;
      right: 0;
      top: -6px;
    }
    .del-chat {
      width: 30px;
      justify-content: flex-end;
    }
    .detailed-chat {
      max-width: 230px;
      @include media-breakpoint-down(xs) {
        max-width: 180px;
      }
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.chat-panel {
  // @include media-breakpoint-up(lg) {
  //   padding-left: 35px;
  // }
  // @include media-breakpoint-down(md) {
  //   height: calc(100vh - 185px);
  //   overflow: auto;
  // }
  .chat-hed {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    padding: 13px;
    position: relative;
    z-index: 9;
    @include media-breakpoint-down(md) {
      border-radius: 0 !important;
      box-shadow: none;
      background-color: $primary !important;
      padding: 5px 15px 0;
      position: sticky;
      top: 0;
      .chat-name {
        h6 {
          color: $white;
        }
      }
      .avatar {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        margin-right: 10px;
      }
      .avatar-main {
        display: none;
      }
      .avatar-sub {
        position: relative;
        bottom: auto;
        right: auto;
        width: 40px;
        height: 40px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .chat-inner {
    @include media-breakpoint-down(md) {
      padding: 15px;
      height: calc(100vh - 340px);
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 25px;
      height: calc(100vh - 445px);
      min-height: 300px;
      overflow: auto;
      padding: 10px 0 0;
    }
  }
  .chat-actions {
    ul {
      padding-right: 11px;
      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
      li {
        margin-left: 20px;
        height: 42px;
        min-width: 42px;
        max-width: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 50%;
        background-color: $bg-light;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(md) {
          background: transparent;
          height: 25px;
          min-width: auto;
          max-width: 30px;
        }
        i {
          color: $body-color;
          @include media-breakpoint-down(md) {
            color: $white;
          }
        }
        .dropdown-menu {
          right: 0 !important;
          left: auto !important;
        }
      }
    }
  }
}
.chat-period {
  padding: 30px 0;
  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }
  &::before {
    content: '';
    display: block;
    background-color: #e8e8e8;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  span {
    background-color: $body-bg;
    position: relative;
    padding: 0 8px;
  }
}
.chat-in {
  display: flex;
  margin-bottom: 10px;
  .chat-propic {
    min-width: 40px;
    border-radius: 50%;
  }
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .chat-content {
    padding: 10px;
    max-width: 260px;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    padding-right: 65px;
    margin: 0 15px;
    p {
      margin: 0;
    }
    .chat-time-read {
      position: absolute;
      right: 8px;
      font-size: 10px;
      padding: 4px 0;
    }
  }
}
.chat-send {
  .chat-content {
    background-color: #ffd3d3;
  }
  justify-content: flex-end;
  flex-direction: row-reverse !important;
  justify-content: end;
  @include media-breakpoint-up(lg) {
    padding-right: 15px;
  }
}
.chat-receive {
}
.chat-tick {
  &.icon-chat-tick {
    &:before {
      font-size: 8px;
    }
  }
  &.red {
    &:before {
      color: $primary;
    }
  }
}
.offer-action {
  .offer-price {
    color: $primary;
    font-size: 22px;
    font-weight: 600;
  }
  .chat-time-read {
    bottom: 0px;
  }
  .chat-in-buttons {
    margin: 8px 15px;
  }
}

.rejected-action {
  .chat-content {
    padding-right: 10px;
    padding-bottom: 22px;
  }
  .reject-content {
    padding: 8px 10px;
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    margin-bottom: 8px;
    border-radius: 1px;
  }
  .chat-time-read {
    bottom: 0px;
  }
  .reject-text {
    color: $red;
  }
}

.accepted-action {
  .chat-in-buttons {
    margin: 8px 15px;
  }
  .chat-content {
    padding-right: 10px;
    padding-bottom: 22px;
  }
  .accept-content {
    padding: 8px 10px;
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    margin-bottom: 8px;
    border-radius: 1px;
  }
  .chat-time-read {
    bottom: 0px;
  }
  .accept-text {
    color: $success;
  }
}

.styleTwo {
  .nav-tabs {
    border-bottom: 1px solid $input-border-color;
  }
  .tab-hed {
    @include media-breakpoint-down(md) {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
    ul {
      li {
        margin-right: 0;
        @include media-breakpoint-down(md) {
          a {
            padding: 15px 5px !important;
          }
        }
        @include media-breakpoint-down(md) {
          &:first-child {
            &::after {
              content: '';
              display: block;
              width: 1px;
              background-color: #c4c4c4;
              position: absolute;
              top: 15px;
              left: 50%;
              bottom: 15px;
            }
          }
        }
      }
    }
    background-color: $white;
    position: relative;
    @include media-breakpoint-up(lg) {
      background-color: $gray-400;
      &::before {
        content: '';
        width: 47px;
        height: 47px;
        background-color: $gray-400;
        display: block;
        position: absolute;
        top: -16px;
        border-radius: 50%;
        transform: translate(-50%, 0%);
        left: 50%;
        display: none;
      }
    }
    .nav-link {
      padding: 0;
      border: none;
      font-size: rem(18);
      color: $text-muted;
      padding: 10px 0px;
      margin-right: 0px;
      font-weight: 500;
      &.active {
        background-color: transparent;
        border: none;
        color: $text-gray-dark;
        border-bottom: 2px solid $primary;
      }
    }
  }
  .tab-content {
    .chat-words-block {
      padding: 10px 30px;

      display: flex;
      align-items: center;
      @include media-breakpoint-up(lg) {
        min-height: 80px;
      }
      @include media-breakpoint-down(md) {
        padding: 10px 5px;
        overflow: auto;
        white-space: nowrap;
      }
      .chat-word {
        background-color: #f1f1f1;
        padding: 5px 13px;
        display: inline-block;
        margin: 4px;
        margin-bottom: 7px;
        cursor: pointer;
      }
    }
  }
  .price-offering-section {
    @include media-breakpoint-up(lg) {
      padding: 35px 35px;
    }
    @include media-breakpoint-down(md) {
      padding: 5px 15px;
    }
    .offer-add {
      background-color: transparent;
      border: 0;
      font-size: 30px;
      padding: 0;
      color: $text-gray-dark;
      padding-left: 10px;
      font-weight: 600;
      max-width: 200px;
      border-bottom: 1px solid $gray-600;
      border-radius: 0;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
    .input-group-prepend {
      span {
        font-size: 30px;
        padding: 0;
        color: $text-gray-dark;
        font-weight: 600;
      }
    }
    .input-group:focus-within {
      span {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.chat-text {
  padding: 10px 15px;
  border-top: 1px solid #dedede;
  @include media-breakpoint-down(md) {
    margin: 0px;
  }
  .file-attach-chat {
    .fileup {
      cursor: pointer;
      i {
        font-size: 16px;
      }
    }
    .file-attach {
      min-width: 30px;
      max-width: 30px;
    }
    .type-chat {
      input {
        background: transparent;
        border: none;
        height: 30px;
        padding: 0;
        &:focus {
          box-shadow: none;
          border: none;
          outline: none;
        }
        &::placeholder {
          color: $gray-500;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $gray-500;
        }

        &::-ms-input-placeholder {
          color: $gray-500;
        }
      }
    }
  }
}
.offeradd-inner {
  @include media-breakpoint-down(xs) {
    justify-content: space-between;
  }
  .about-offer {
    font-size: 13px;
    border-radius: 8px;
    padding: 0 10px;
    min-width: 295px;
    max-width: 295px;
    position: relative;
    @include media-breakpoint-down(md) {
      min-width: 220px;
      max-width: 220px;
    }
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: -6px;
      left: 20px;
    }
    &.best {
      background-color: $success;
      &:before {
        border-bottom: 9px solid $success;
      }
    }
    &.average {
      background-color: $warning;
      &:before {
        border-bottom: 9px solid $warning;
      }
    }
    &.bad {
      background-color: $danger;
      &:before {
        border-bottom: 9px solid $danger;
      }
      .icon-thumb {
        transform: rotate(184deg);
        display: block;
        margin-top: 2px;
      }
    }
    p {
      line-height: 15px;
    }
  }
}
.fix-chat-footer {
  @include media-breakpoint-up(lg) {
    // position: fixed;
    // bottom: 0;
    background-color: $gray-100;
    z-index: 99;
  }
}
.chatlist-align {
  @include media-breakpoint-down(md) {
    transition: 0.5s all ease;
  }
  @include media-breakpoint-up(lg) {
    min-width: 465px;
    max-width: 465px;
  }
}
.chat-list {
  @include media-breakpoint-down(md) {
    background: transparent !important;
  }
}
.mob-product-view {
  background-color: $white;
  padding: 15px;
  margin: 0 -15px;
  border-bottom: 1px solid $input-border-color;
}

.chat-align {
  @include media-breakpoint-up(lg) {
    max-width: calc(100% - 465px) !important;
  }
  @include media-breakpoint-down(md) {
    transform: translateX(0);
    background-color: $body-bg;
    transition: 0.5s all ease;
  }
}

.open-chatwindow {
  @include media-breakpoint-down(md) {
    .chat-align {
      transform: translateX(-100%);
    }
    .chatlist-align {
      transform: translateX(-100%);
    }
  }
}

#country-codes {
  .form-control {
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      border-right: #bcbcbc 2px solid;
      border-bottom: #bcbcbc 2px solid;
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      margin-top: -4px;
    }
  }
  .dropdown-menu {
    min-width: 1rem;
  }
}
.country-selection {
  max-width: 90px;
}
.mob-product-detail {
  padding-left: 80px;
  min-height: 70px;
  position: relative;
  figure {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 5px;
    img {
      width: 70px;
      height: auto;
    }
  }
}
.chat-user-details {
  @include media-breakpoint-up(lg) {
    padding-left: 90px;
    min-height: 70px;
    padding-right: 185px;
  }
  @include media-breakpoint-down(md) {
    min-height: 40px;
    padding-right: 95px;
    padding-left: 65px;
  }
  position: relative;
  .product-image {
    width: 70px;
    @include media-breakpoint-up(lg) {
      height: 70px;
      left: 0;
    }
    @include media-breakpoint-down(md) {
      height: 40px;
      left: 15px;
    }
    position: absolute;
    top: 0;
  }
  .icon-prev {
    left: -15px;
    top: 0;
    height: 100%;
  }
  .chat-actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}
.open-chatwindow {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}
.text-truncate-multiple {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat-inner div[style*='inset'] {
  margin-bottom: 0 !important;
}
