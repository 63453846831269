.detail-block{
  @include media-breakpoint-up(lg) {
    .right-pane{
      max-width: 424px;
    }
    .left-pane{
      max-width: calc(100% - 424px);
    }
  }
}
.productBox {
  margin-bottom: rem(20);
  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }

  .swap {
    top: 10px;
    right: 10px;
    background: rgba($black, 0.2);
    width: 21px;
    height: 21px;
  }
  figure {
    height: 228px;
    @include media-breakpoint-down(xs) {
      height: 189px;
    }
  }
  // figcaption {
  //   height: 169px;
  //   @include media-breakpoint-down(xs) {
  //     height: 161px;
  //   }
  // }
  &__info {
    @include media-breakpoint-up(md) { 
      padding: rem(16) rem(15) rem(8);
    }
    @include media-breakpoint-down(sm) { 
      padding: 5px;
    }
  }
  &_auction,&_lead,&_sale{
    .btn{
      @include media-breakpoint-down(sm) { 
      font-size: 13px;
      height: 33px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      }
    }
    @include media-breakpoint-up(md) { 
      padding: 0 rem(15) rem(12);
    }
    @include media-breakpoint-down(sm) { 
      padding: 0 5px 12px;
      .font-md{
          font-size: 12px!important;
      }
      .font-base{
        font-size: 14px!important;
      }
    }
    padding: 0 rem(15) rem(12);
    &--action{
      display: flex;
      justify-content: space-between;
      align-items: center;     
    }
  }
  &__actions {
    height: 30px;
  
  }
  &__title {
    line-height: rem(16.87);
  }
  &__share-popup {
    left: inherit;
    right:0;
    bottom: 100%;
    width: 290px;
    height: 219px;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transform-origin: bottom left;
    display: none;
    transition: all 0.2s ease-in-out;
    &--open {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      display: block;
    }
    &--right {
      left: inherit;
      right: -5px;
      transform-origin: bottom right;
    }
  }
  &__share-list {
    li {
      margin-right: rem(15);
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__share {
    width: 49px;
    height: 49px;
    &--twitter {
      background-color: #e8f6fe;
    }
    &--fb {
      background-color: #e8f1fe;
    }
    &--whatsapp {
      background-color: #e9fbf0;
    }
    &--telegram {
      background-color: #e6f3fa;
    }
  }
  &__share-link {
    height: 33px;
    input,
    button {
      &:focus {
        outline: 0;
      }
    }
  }
  &__share-close {
    right: 15px;
    top: 15px;
  }
}
.featured {
  left: -3px;
  top: 10px;
  color: #f7f7f7;
  padding: 4px 14px 4px 10px;
  border-radius: 0 50em 50em 0;
  position: relative;
  i {
    color: #fcfcfc;
  }
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-color: transparent #ff7676 transparent transparent;
  }
}
