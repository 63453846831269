@font-face {
  font-family: 'Circular Pro';
  src: url('../../assets/fonts/CircularPro-Book.woff2') format('woff2'),
    url('../../assets/fonts/CircularPro-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('../../assets/fonts/CircularPro-Medium.woff2') format('woff2'),
    url('../../assets/fonts/CircularPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('../../assets/fonts/CircularPro-Bold.woff2') format('woff2'),
    url('../../assets/fonts/CircularPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
