@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot?ko0jv8');
  src: url('../../assets/fonts/icomoon.eot?ko0jv8#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?ko0jv8') format('truetype'),
    url('../../assets/fonts/icomoon.woff?ko0jv8') format('woff'),
    url('../../assets/fonts/icomoon.svg?ko0jv8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subscription:before {
  content: "\e9f9";
}
.icon-category:before {
  content: "\e9fa";
}
.icon-leads:before {
  content: "\e9fb";
}
.icon-bid:before {
  content: "\e9f6";
}
.icon-sort:before {
  content: "\e9f5";
}
.icon-auction:before {
  content: "\e9f4";
}
.icon-sell-strock:before {
  content: "\e9f1";
  color: #e10000;
}
.icon-usergroup:before {
  content: "\e9f2";
  color: #7e7e7e;
}
.icon-home:before {
  content: "\e9f3";
  color: #7e7e7e;
}
.icon-insta .path1:before {
  content: "\e9ed";
  color: rgb(63, 114, 155);
}
.icon-insta .path2:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-pinterest .path1:before {
  content: "\e9ef";
  color: rgb(203, 32, 39);
}
.icon-pinterest .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(241, 242, 242);
}
.icon-mail2 .path1:before {
  content: "\e9de";
  color: rgb(196, 196, 196);
}
.icon-mail2 .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail2 .path3:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone2 .path1:before {
  content: "\e9e1";
  color: rgb(196, 196, 196);
}
.icon-phone2 .path2:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path1:before {
  content: "\e9e3";
  color: rgb(196, 196, 196);
}
.icon-gplus .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path5:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path6:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path7:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fb:before {
  content: "\e9ea";
  color: #3a5a99;
}
.icon-verified2 .path1:before {
  content: "\e9eb";
  color: rgb(1, 183, 83);
}
.icon-verified2 .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-edit:before {
  content: "\e9dd";
  color: #e10000;
}
.icon-back:before {
  content: "\e9d8";
  color: #fff;
}
.icon-calendar:before {
  content: "\e9d9";
  color: #949292;
}
.icon-download:before {
  content: "\e9da";
  color: #7e7e7e;
}
.icon-print:before {
  content: "\e9db";
  color: #7e7e7e;
}
.icon-pic-upload:before {
  content: "\e9dc";
  color: #fff;
}
.icon-insights:before {
  content: "\e9cf";
  color: #4e4d4d;
}
.icon-invoice:before {
  content: "\e9d0";
  color: #4e4d4d;
}
.icon-my-ads:before {
  content: "\e9d1";
}
.icon-my-orders:before {
  content: "\e9d2";
  color: #4e4d4d;
}
.icon-notification1:before {
  content: "\e9d3";
  color: #4e4d4d;
}
.icon-profile:before {
  content: "\e9d4";
  color: #7e7e7e;
}
.icon-save-card:before {
  content: "\e9d5";
  color: #4e4d4d;
}
.icon-settings:before {
  content: "\e9d6";
  color: #4e4d4d;
}
.icon-support:before {
  content: "\e9d7";
  color: #4e4d4d;
}
.icon-share:before {
  content: "\e9ce";
}
.icon-notification-filled:before {
  content: "\e9cc";
}
.icon-filter:before {
  content: "\e9cd";
}
.icon-search:before {
  content: "\e9cb";
}
.icon-verified:before {
  content: "\e99a";
  color: #01b753;
}
.icon-arrow-down:before {
  content: "\e99b";
}
.icon-attach:before {
  content: "\e99c";
}
.icon-bin:before {
  content: "\e99d";
}
.icon-chat:before {
  content: "\e99e";
}
.icon-close-sm:before {
  content: "\e99f";
}
.icon-copy:before {
  content: "\e9a0";
}
.icon-fb-rounded:before {
  content: "\e9a1";
  color: #2196f3;
}
.icon-flag:before {
  content: "\e9a2";
}
.icon-gps:before {
  content: "\e9a3";
}
.icon-heart-filled:before {
  content: "\e9a4";
}
.icon-heart-outline:before {
  content: "\e9a5";
}
.icon-insta-rounded .path1:before {
  content: "\e9a6";
  color: rgb(237, 73, 86);
}
.icon-insta-rounded .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-list:before {
  content: "\e9a8";
}
.icon-location:before {
  content: "\e9a9";
}
.icon-mail1:before {
  content: "\e9aa";
}
.icon-minus:before {
  content: "\e9ab";
}
.icon-more:before {
  content: "\e9ac";
}
.icon-next:before {
  content: "\e9ad";
}
.icon-notification:before {
  content: "\e9ae";
}
.icon-offer:before {
  content: "\e9af";
}
.icon-phone1:before {
  content: "\e9b0";
}
.icon-photo:before {
  content: "\e9b1";
}
.icon-plus:before {
  content: "\e9b2";
}
.icon-prev:before {
  content: "\e9b3";
}
.icon-questions:before {
  content: "\e9b4";
}
.icon-read:before {
  content: "\e9b5";
}
.icon-rounded-twitter .path1:before {
  content: "\e9b6";
  color: rgb(85, 172, 238);
}
.icon-rounded-twitter .path2:before {
  content: "\e9b7";
  margin-left: -1.0380859375em;
  color: rgb(255, 255, 255);
}
.icon-sell .path1:before {
  content: "\e9b8";
  color: rgb(30, 29, 29);
}
.icon-sell .path2:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(255, 204, 3);
}
.icon-sell .path3:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sell .path4:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-share-fb:before {
  content: "\e9bc";
  color: #1877f2;
}
.icon-share-telegram:before {
  content: "\e9bd";
  color: #08c;
}
.icon-share-twitter:before {
  content: "\e9be";
  color: #03a9f4;
}
.icon-share-whatsapp:before {
  content: "\e9bf";
  color: #4ec753;
}
.icon-star:before {
  content: "\e9c0";
}
.icon-star-filled-2:before {
  content: "\e9c1";
}
.icon-star-outline-2:before {
  content: "\e9c2";
}
.icon-swap:before {
  content: "\e9c3";
}
.icon-thumb:before {
  content: "\e9c4";
}
.icon-tick:before {
  content: "\e9c5";
}
.icon-visible:before {
  content: "\e9c6";
}
.icon-warning .path1:before {
  content: "\e9c7";
  color: rgb(225, 0, 0);
}
.icon-warning .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-warning .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-youtube-rounded:before {
  content: "\e9ca";
  color: #dd352e;
}
.icon-close:before {
  content: "\e999";
}
.icon-otp-new .path1:before {
  content: "\e984";
  color: rgb(255, 133, 133);
}
.icon-otp-new .path2:before {
  content: "\e985";
  margin-left: -1.01171875em;
  color: rgb(249, 93, 93);
}
.icon-otp-new .path3:before {
  content: "\e986";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path4:before {
  content: "\e987";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path5:before {
  content: "\e988";
  margin-left: -1.01171875em;
  color: rgb(249, 93, 93);
}
.icon-otp-new .path6:before {
  content: "\e989";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path7:before {
  content: "\e98a";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path8:before {
  content: "\e98b";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path9:before {
  content: "\e98c";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path10:before {
  content: "\e98d";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path11:before {
  content: "\e98e";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path12:before {
  content: "\e98f";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path13:before {
  content: "\e990";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path14:before {
  content: "\e991";
  margin-left: -1.01171875em;
  color: rgb(225, 0, 0);
}
.icon-otp-new .path15:before {
  content: "\e992";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path16:before {
  content: "\e993";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path17:before {
  content: "\e994";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path18:before {
  content: "\e995";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path19:before {
  content: "\e996";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path20:before {
  content: "\e997";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-otp-new .path21:before {
  content: "\e998";
  margin-left: -1.01171875em;
  color: rgb(255, 255, 255);
}
.icon-avatar .path1:before {
  content: "\e900";
  color: rgb(255, 133, 133);
}
.icon-avatar .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(249, 93, 93);
}
.icon-avatar .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-avatar .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-avatar .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-avatar .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(249, 93, 93);
}
.icon-avatar .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 133, 133);
}
.icon-avatar .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(249, 93, 93);
}
.icon-avatar .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path15:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path16:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path17:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path18:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path19:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path20:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path21:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path22:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path23:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path24:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path25:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path26:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path27:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path28:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path29:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-avatar .path30:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(225, 0, 0);
}
.icon-business:before {
  content: "\e91e";
  color: #e10000;
}
.icon-facebook:before {
  content: "\e91f";
  color: #3b5999;
}
.icon-google .path1:before {
  content: "\e920";
  color: rgb(251, 187, 0);
}
.icon-google .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(81, 142, 248);
}
.icon-google .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(40, 180, 70);
}
.icon-google .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(241, 67, 54);
}
.icon-individual:before {
  content: "\e924";
  color: #fff;
}
.icon-otp .path1:before {
  content: "\e925";
  color: rgb(229, 37, 45);
}
.icon-otp .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path4:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path5:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path6:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path7:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path8:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path9:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path10:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path11:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path12:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path13:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path14:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path15:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path16:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path17:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path18:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path19:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path20:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path21:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path22:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path23:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path24:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path25:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path26:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path27:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path28:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path29:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path30:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path31:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path32:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path33:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path34:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(229, 39, 46);
}
.icon-otp .path35:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(229, 39, 46);
}
.icon-otp .path36:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(229, 39, 46);
}
.icon-otp .path37:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path38:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path39:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path40:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path41:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path42:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path43:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path44:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path45:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path46:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path47:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path48:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path49:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path50:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path51:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path52:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path53:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path54:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path55:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path56:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path57:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path58:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path59:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path60:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path61:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path62:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path63:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path64:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path65:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path66:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path67:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path68:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path69:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path70:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path71:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path72:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path73:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path74:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path75:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp .path76:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(229, 37, 45);
}
.icon-otp-img .path1:before {
  content: "\e971";
  color: rgb(172, 5, 5);
  opacity: 0.5;
}
.icon-otp-img .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(58, 204, 108);
}
.icon-otp-img .path3:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-otp-img .path4:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(58, 204, 108);
}
.icon-otp-img .path5:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-otp-img .path6:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(58, 204, 108);
}
.icon-otp-img .path7:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-otp-img .path8:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(80, 0, 0);
}
.icon-otp-img .path9:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(251, 178, 181);
}
.icon-otp-img .path10:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(251, 89, 52);
}
.icon-otp-img .path11:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(251, 178, 181);
}
.icon-otp-img .path12:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(254, 216, 26);
}
.icon-otp-img .path13:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(255, 255, 253);
}
.icon-otp-img .path14:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(33, 33, 33);
}
.icon-otp-img .path15:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(251, 178, 181);
}
.icon-otp-img .path16:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(94, 90, 107);
}
.icon-phone11:before {
  content: "\e981";
  color: #4e4d4d;
}
.icon-tick1 .path1:before {
  content: "\e982";
  color: rgb(1, 183, 83);
}
.icon-tick1 .path2:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-close-rounded:before {
  content: "\e9f8";
}
.icon-logout:before {
  content: "\e9fc";
}
.icon-chat-tick:before {
  content: "\e9f7";
}
