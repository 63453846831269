@import '../../styles/custom-variable/variables';

.Input {
  position: relative;
  margin-bottom: 1rem;
  font-size: 1rem;
  &--gap-lg {
    margin-bottom: rem(32);
  }
  &--gap-md {
    margin-bottom: rem(25);
  }
  &--gap-sm {
    margin-bottom: 10px;
  }
  &--gap-no {
    margin-bottom: 0;
  }
  &__label {
    display: inline-block;
    margin-bottom: 6px;
    color: $gray-700;
    font-size: 0.9375em;
    width: 100%;
  }
  &__field {
    display: block;
    width: 100%;
    padding: 1.3125rem 0.75rem;
    font-size: 0.9375em;
    line-height: 1rem;
    color: $gray-900;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    outline: none;
    font-family: inherit;
    &::-webkit-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &::-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-ms-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:focus {
      outline: none;
      box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    &--noborder {
      border: none !important;
      box-shadow: none !important;
    }
    &--icon {
      background-color: transparent;
      // z-index: 1;
      position: relative;
      padding-right: 40px;
      + i {
        position: absolute;
        bottom: 14px;
        font-size: 1.25em;
        color: $gray-400;
        z-index: 0;
        right: 15px;
        &.icon {
          &-sm {
            font-size: 0.8em;
          }
          &-xs {
            font-size: 0.5em;
            bottom: 17px;
          }
        }
        &.icon--primary {
          color: $primary;
        }
      }
      + .InputLoading {
        position: absolute;
        bottom: 24px;
        z-index: 0;
        right: 15px;
      }
    }
    &--disabled {
      background: #f2f2f2;
    }
  }
  &::placeholder {
    color: $gray-400;
    font-family: inherit;
  }
  &__error {
    display: block;
    color: red;
    font-size: 13px;
    margin-top: 3px;
  }
  &__mobile {
    position: relative;
    z-index: 0;
    margin-left: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0;
    padding-right: 6px;
    padding-left: 117px;
  }
  &Loading {
    color: hsl(0, 0%, 80%);
    display: flex;
    transition: color 150ms;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    &__dot {
      background-color: currentColor;
      border-radius: 1em;
      display: inline-block;
      height: 1em;
      vertical-align: top;
      width: 1em;
      margin-left: 4px;
      &F {
        -webkit-animation: animation-stj4i2 1s ease-in-out 0ms infinite;
        animation: animation-stj4i2 1s ease-in-out 0ms infinite;
      }
      &M {
        -webkit-animation: animation-stj4i2 1s ease-in-out 200ms infinite;
        animation: animation-stj4i2 1s ease-in-out 200ms infinite;
      }
      &L {
        -webkit-animation: animation-stj4i2 1s ease-in-out 360ms infinite;
        animation: animation-stj4i2 1s ease-in-out 360ms infinite;
      }
    }
  }
}
@keyframes animation-stj4i2 {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
.basic-single {
  .select {
    &__control {
      border-color: $input-border-color !important;
      box-shadow: none !important;
      &:hover {
        border-color: $border-color !important;
      }
      .select__input {
        input {
          padding: 0 !important;
        }
      }
    }
    &__menu {
      .select__option {
        &.select__option--is-focused {
          background-color: rgba($primary, 0.1);
        }
      }
    }
  }
}
