@import '../../../styles/custom-variable/variables';

.InputGroup {
  display: flex;
  .Input {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-bottom: 0 !important;
    &:last-child {
      .Input__field {
        border-left: none;
        border-radius: 0 4px 4px 0;
      }
    }
    &__field {
      &.disable-number-scrolls {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
