@import '../../../styles/custom-variable/variables';

.checkbox {
  position: relative;
  font-size: 1rem;

  &--mg {
    margin-bottom: 0.5rem;
  }

  .checkbox_label {
    &--sm {
      font-size: 1rem !important;
    }
  }

  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    display: none;
  }

  [type='checkbox']:not(:checked) + label {
    color: $gray-700;
  }

  [type='checkbox']:checked + label {
    color: $gray-900;
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 1.1875em;
    line-height: 23px;
  }

  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 23px;
    height: 23px;
    border: 2px solid #d9d9d9;
    background: #fff;
    border-radius: 4px;
  }

  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    position: absolute;
    content: '';
    top: 4px;
    left: 8px;
    width: 6px;
    height: 12px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    transition: all 0.2s;
    transform: rotate(45deg);
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:before {
    border-color: $primary;
    background-color: $primary;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }

  &__sm {
    [type='checkbox']:not(:checked) + label,
    [type='checkbox']:checked + label {
      position: relative;
      padding-left: 35px;
      font-size: 1em;
      line-height: 19px;
    }

    [type='checkbox']:not(:checked) + label:before,
    [type='checkbox']:checked + label:before {
      width: 19px;
      height: 19px;
    }

    [type='checkbox']:not(:checked) + label:after,
    [type='checkbox']:checked + label:after {
      top: 2px;
      left: 7px;
      width: 6px;
      height: 12px;
    }
  }
}
