@import '../../../styles/custom-variable/variables';

.react-datepicker-wrapper {
  width: 100%;
  .Input__field {
    background-color: transparent;
  }
}

.react-datepicker {
  border: 1px solid $border-color;
  &__current-month,
  &-time__header,
  &-year-header {
    font-weight: 500;
  }
  &__navigation {
    border: 0.4rem solid transparent;
    &--previous {
      border-right-color: $text-muted;
    }
    &--next {
      border-left-color: $text-muted;
    }
  }
  &__month-select,
  &__year-select {
    border: 1px solid $border-color;
    padding: 3px 5px;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
  &__header {
    border-bottom: 1px solid $border-color;
  }
  &__day--today,
  &__month-text--today,
  &__quarter-text--today {
    font-weight: 500;
  }
  &__day,
  &__month-text,
  &__quarter-text {
    &:hover {
      border-radius: 3px;
      background-color: rgba(2, 128, 128, 0.1);
    }
  }
  &-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: $border-color;
  }
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range {
    background-color: $primary;
    border-radius: 3px;
  }
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected {
    &:hover {
      background-color: $primary;
    }
  }
}
