.banner{
    max-height: 460px;
    overflow: hidden;
    &__item{
       max-height: 460px;
        img{
            width: 100%;
        }
        .banner-cnt{
            position: absolute;
            left:0;
            top:0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
    &--sm{
        max-height: 290px;
        .banner__item{
           max-height: 290px;
            
        }
        @include media-breakpoint-down(md){
            max-height: 245px;
            .banner__item{
                max-height: 245px;
            }
        }
    }
}