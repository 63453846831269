.slick-prev {
  &::before {
    font-size: 40px !important;
  }
}
.slick-next {
  &::before {
    font-size: 40px !important;
  }
}
