@import '../../../styles/custom-variable/variables';

.switch {
  html[dir='ltr'] & {
    margin-left: auto;
  }
  html[dir='rtl'] & {
    margin-right: auto;
  }
}

.switch__grow {
  display: flex;
}

.switch__input {
  display: none;
}

.switch__input + .switch__btn {
  outline: 0;
  display: block;
  width: 52px;
  height: 24px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch__input + .switch__btn:after,
.switch__input + .switch__btn:before {
  position: relative;
  display: block;
  content: '';
  width: 20px;
  height: 100%;
}

.switch__input + .switch__btn:after {
  html[dir='ltr'] & {
    left: 0;
  }
  html[dir='rtl'] & {
    right: 0;
  }
}

.switch__input + .switch__btn:before {
  display: none;
}

.switch__input:checked + .switch__btn:after {
  html[dir='ltr'] & {
    left: 28px;
  }
  html[dir='rtl'] & {
    right: 28px;
  }
}

.switch__input + .switch__btn {
  background: #dbdbdb;
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.switch__input + .switch__btn:after {
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.switch__input:checked + .switch__btn {
  background: $primary;
}
