@import '../../../styles/custom-variable/variables';

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  width: 250px;
  border: 1px solid $border-color;
  &__current-month,
  &-time__header,
  &-year-header {
    font-weight: 500;
  }
  &__navigation {
    border: 0.4rem solid transparent;
    &--previous {
      border-right-color: $text-muted;
    }
    &--next {
      border-left-color: $text-muted;
    }
  }
  &__month-select,
  &__year-select {
    border: 1px solid $border-color;
    padding: 3px 5px;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
  &__header {
    border-bottom: 1px solid $border-color;
  }
  &__day--today,
  &__month-text--today,
  &__quarter-text--today {
    font-weight: 500;
  }
  &__day,
  &__month-text,
  &__quarter-text {
    &:hover {
      border-radius: 3px;
      background-color: rgba(2, 128, 128, 0.1);
    }
  }
  &-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: $border-color;
  }
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range {
    background-color: $primary;
    border-radius: 3px;
  }
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected {
    &:hover {
      background-color: $primary;
    }
  }
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    height: 75px!important;
    width: 100%!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  height: auto!important;overflow: auto;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    height: auto;
    padding: 5px 10px;
    white-space: normal;
    display: inline-flex;
    background: #f5f5f5;
    margin: 1px;
    font-size: 11px;
    width: 32%;
    
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background-color:$primary!important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 10px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    text-align: left;
}
.react-datepicker__month-container,.react-datepicker__time-container{
    float: none;
}
.react-datepicker__time-container{
    width: 100%;
    border-left:0;
}
.react-datepicker__input-container {
  .form-control{
    background: url(../../../assets/images/calander.png) no-repeat right center #fff !important;
  }
  .icon-calendar{
    display: none;
  }
}
