.choose-cat{
    display: flex;
    flex-flow: column;
    
}
.login-categories {
    
    h5{
        font-size: 1.75rem;
        @include media-breakpoint-down(lg){
            font-size: 22px;
        }
    }
}
.list-category{
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: .2s;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding:10px 18px;
    border-radius: 25px;

    &:focus-visible {
        outline: none;
    }

    label {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: 100% !important;
        width:100% !important;
        padding: 0 !important;
        font-size: 12px !important;
        text-align: center;
        margin: 0 !important;
        line-height: 14px !important;
        position: static !important;
    }
    .figure{
        width:auto;
        height:24px;
        img{
            height: 100%;
        }
    }
    p{
        line-height: 15px;
    }
    .Input--gap-sm{
        margin-bottom:0 !important;
        position: static;
        .checkbox {
            position: static;
        }
    }

    [type="checkbox"]:checked,
    input[type="checkbox"]:not(:checked) {
        visibility: hidden;
        position: absolute;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
    content: "";
        position: absolute;
        top: 0px !important;
        width: 100% !important;
        height: 100% !important;
        background: transparent;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        left:0 !important;
        border-radius: 25px !important;
        overflow: hidden;
        opacity: 0;;
    }

    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after {
        display: none;
    }

    [type="checkbox"]:checked + label{
        &:before {
            background:rgba($primary,.08) !important; 
            opacity: 1;
            border: 1px solid rgba($primary, 0.5) !important;
        }
        p{
            color:$text-gray-dark;
        } 
    }
}
.profile-edit-form{
    .form-group{
        font-size: 14px;
        .select__control{
            font-size: 14px;
            height: 47px;
            border-color: $input-border-color !important;
        }
    }
    .flag-dropdown{
        border-color: $input-border-color !important;
    }
    .dob{
        input{
            height: 47px;
            background-color:$white;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 14px;
        }
        .icon-calendar{
            bottom: 14px;
        }
    }
    .Input--gap-md{
        margin-bottom:0 !important;
    }
}