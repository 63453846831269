@import '../../../styles/custom-variable/variables';

.TextArea {
  position: relative;
  font-size: 1rem;
  &--gap-lg {
    margin-bottom: rem(32);
  }
  &--gap-md {
    margin-bottom: rem(25);
  }
  &--gap-sm {
    margin-bottom: 10px;
  }
  &__label {
    display: inline-block;
    margin-bottom: 8px;
    color: $gray-700;
    font-size: 14px;
    line-height: normal;
    width: 100%;
    &--full {
      width: 100%;
    }
  }
  &__field {
    display: block;
    width: 100%;
    padding: rem(16) rem(13);
    font-size: rem(15);
    line-height: 22px;
    color: $gray-900;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $border-color;
    border-radius: 4px;
    outline: none;
    font-family: inherit;
    height: 100px;
    resize: none;
    &::-webkit-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &::-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-ms-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:focus {
      outline: none;
    }
    &--lg {
      font-size: 2.0625em !important;
      font-weight: 500 !important;
      color: $gray-900 !important;
    }
    &--sm {
      padding: 0.75rem;
      font-size: 0.9375em !important;
    }
    &--border-sm {
      border-width: 1px;
    }
    &--noborder {
      border: none !important;
      padding: 0;
    }
    &--disabled {
      background: #f2f2f2;
    }
  }
  &::placeholder {
    color: $gray-400;
    font-family: inherit;
  }
  &__error {
    display: block;
    color: red;
    font-size: 0.875rem;
  }
}
