#bid-history{
    &.modal-dialog{
        max-width:532px;
        th,td{
            border-color:#ececec;
        }
        td{
            padding:12px;
            line-height: 1;
        }
        .btn{
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 111px;
            float: right;
        }
        .modal-body{
            padding: 21px 44px 45px;
            @include media-breakpoint-down(md) {
                padding: 21px 15px 45px;
            }
        }
        .modal-content{
            border:none !important;
        }
    }
}