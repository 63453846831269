@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'custom/checkbox-radio';


.buy-prod{
  max-width:892px;
  width: 100%;
  margin:0 auto;
  @include media-breakpoint-down(sm){
    margin-bottom:-50px ;
    .btn-position{
      .btn{
        position: fixed;
        bottom:0;
        left:0;
        font-size: 15px;
        width: 100%;
        padding: 10px 0;
        border-radius: 0!important;
      }
    }
  }
  h1{
    font-size: rem(26);
  }
  .time-field{
    li{
      width:23%;
      @include media-breakpoint-down(xs) {
        width:18%;
        text-align: center;
      }
    }
    span{
      font-size: rem(28);
      color:$primary;
      line-height: 35px;
    }
  }
 
}
.seller-field{
  figure{
    img{
      width:46px;
      height:46px;
    }
  }
}
.mid-field{
  h6{
    font-size:19px;
  }
}
