header {

  @include media-breakpoint-up(md) {
    position: sticky;
    top:0;
    z-index: 1000;
  }
    @include media-breakpoint-down(sm) {
      position: relative;
    z-index: 3;
  }
  .#{&}-top {
    @include media-breakpoint-up(md) {
      padding: rem(7) 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 0 rem(7);
    }
    z-index: 1;
    .searchBox {
      height: 46px;
      @include media-breakpoint-down(md) {
        height: 43px;
      }
      &--outer {
        max-width: 718px;
        @include media-breakpoint-down(md) {
          position: fixed;
          background-color: $gray-100;
          top: 0;
          left: 110%;
          height: 100%;
          overflow: auto;
          width: 100%;
          z-index: 99;
          max-width: 100%;
          transition: all 0.3s ease-in-out;
          .open-search & {
            left: 0;
          }
          .searchBox {
            height: auto;
            margin-left: -15px;
            margin-right: -15px;
            padding-top: rem(40);
            background-color: $gray-100 !important;
          }
        }
      }
      &__input {
        //display: flex;
        //align-items: center;
        input {
          border: 0;
          border-radius: $border-radius;
          padding: 0 rem(17);
          @include media-breakpoint-down(md) {
            padding: 0;
            border-bottom: 1px solid $border-color;
            border-radius: 0;
          }
          &:hover,
          &:focus {
            outline: 0;
          }
          &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
          }
          @include media-breakpoint-down(md) {
            height: 30px !important;
            background: transparent;
          }
        }
        width: 100%;
        @include media-breakpoint-up(lg) {
          border-right: 1px solid $gray-200;
          max-width: 404px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: rem(34);
        }
      }
      &__location {
        width: 100%;
        @include media-breakpoint-up(lg) {
          max-width: 234px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: rem(34);
        }
        input {
          &:hover,
          &:focus {
            outline: 0;
          }
          &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
          }
          @include media-breakpoint-down(md) {
            height: 30px !important;
            width: 100%;
            background: transparent;
          }
        }
        &-dropdown {
          width: 371px;
          height: 371px;
          left: 0;
          top: 100%;
          visibility: hidden;
          opacity: 0;
          transform: scale(0.9);
          transform-origin: top left;
          transition: all 0.2s ease-in-out;
          &--open {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
          }
        }
      }
      &__dropdown {
        padding: 0 rem(16);
        cursor: pointer;
        @include media-breakpoint-up(lg) {
          max-width: 194px;
        }
        @include media-breakpoint-down(md) {
          padding: 0;
          border-bottom: 1px solid $border-color;
        }
      }
      &__button {
        width: 100%;
        padding: 3px;
        @include media-breakpoint-up(lg) {
          max-width: 50px;
        }
        @include media-breakpoint-down(md) {
          padding: 3px 15px;
        }
      }
      &--sm {
        input {
          height: 100% !important;
        }
      }
    }
    .sell-btn {
      height: 46px;
    }
  }
  .#{&}-bottom {
    @include media-breakpoint-up(lg) {
      background-color: $white;
    }
    @include media-breakpoint-down(md) {
      box-shadow: none !important;
    }
    .category-dropdown {
      width: 257px;
      &:hover {
        & > span,
        & > i {
          color: $primary;
        }
        .category-dropdown-menu {
          opacity: 1;
          visibility: visible;
          top: 100%;
        }
      }
      @include media-breakpoint-down(xl) {
        width: 150px;
      }
      .category-dropdown-menu {
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        top: 110%;
        transition: all 0.3s ease-in-out;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 2px 2px 2px rgba(0,0,0,0.05);
      }
    }
    .caterogy-list {
      background-color: $body-bg;
      .caterogy-img {
        min-width: 30px;
        img{
          width:auto;
          max-width: 100%;
          max-height: 25px;
        }
      }
      @include media-breakpoint-up(lg){
        li {
          display: none;
        }
        li:nth-child(-n+6){
          display: flex;
        }
      }
      @include media-breakpoint-down(md) {
        overflow: auto;
        font-size: 13px !important;
        .caterogy-img {
          background-color: $white;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }
  }
  .icon-notification-filled,.icon-filter{
      font-size: 22px;
  }
}

.current-user {
  img {
    width: 29px;
    height: 29px;
  }
}

.notify,
.filtered {
  position: relative;
  &::after {
    content: '';
    width: 13px;
    height: 13px;
    position: absolute;
    top: 2px;
    right: 0px;
    background-color: #ffcc03;
    display: inline-block;
    z-index: 1;
    border-radius: 50%;
    border: 2px solid $primary;
  }
}

.user-dropdown {
  a{
    color: $text-gray-dark;
    &:hover{
      color: $link-color;
    }
  }
  .user-dropdown-menu {
    width: 150px; 
  }
  &:hover {
    background-color: rgba($black, 0.15);
    .user-dropdown-menu {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
  &-menu {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transform-origin: top right;
    transition: all 0.2s ease-in-out;
    right: 0;
    top: 100%;
    a {
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

.header-mob {
  height: 50px;
  z-index: 500;
  position: sticky;
  top:0;
  h6{
    color: $white;
  }
}

.filtered {
  &::after {
    top: -5px;
    right: -5px;
  }
}
.autocomplete-dropdown-container{
  top: 100%;
  max-height: 371px;
}
.logo{
  @include media-breakpoint-down(md){
    img{
      max-height: 57px;
    }
  }
}
.search-dropdown{
  width: 100%;
  @include media-breakpoint-up(lg){
    top: 42px;
    left: 0;
    position: absolute;
  }
  max-height: 400px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.1);
}
.open-search{
  overflow: hidden;
  .header-mob{
    z-index: 0;
  }
  .footer-mob{
    display: none!important;
  }
}
.category-responsive {
  @include media-breakpoint-down(md){
    z-index: 100000;
    overflow: auto;
    display: none;
    .category-head{
      z-index: 1000;
      top:0;
      position: sticky;
    }
    .main-category{
      padding-right: 40px;
      min-height: 35px;
      .expand-menu{
        width: 35px;
        height: 35px;
        margin-right:-10px;
        &:before,&:after{
          position: absolute;
          transform: translate(-50%,-50%);
          left: 50%;
          top: 50%;
        }
        &:before{
          content: "";
          background: rgb(59, 59, 59);
          width: 12px;
          height: 2px;
        }
        &:after{
          content: "";
          background: rgb(59, 59, 59);
          width: 2px;
          height: 12px;
          transition: all ease .1s;
        }
        
      }
    }
    .sub-cat{
      display: none;
    }
    li.main-list.active{
      .expand-menu:after{
        opacity: 0;
      }
      .sub-cat{
        display: block!important;
      }
    }
   
    .open-allcategory &{
      display: block;
    }
  }
}
.open-allcategory{
  overflow: hidden;
}