.custom-checkbox{
    position: relative;

    [type='checkbox']:not(:checked),
    [type='checkbox']:checked {
        position: absolute;
        left: -9999px;
        display: none;
    }

    [type='checkbox']:not(:checked) + label {
        color: $gray-700;
    }

    [type='checkbox']:checked + label {
        color: $gray-700;
    }

    [type='checkbox']:not(:checked) + label,
    [type='checkbox']:checked + label {
        position: relative;
        cursor: pointer;
        margin: 0;
        display: inline-flex !important;
        align-items: center;
        font-size: rem(14);
        line-height: rem(22);
        padding-left: 27px;
    }

    [type='checkbox']:not(:checked) + label:before,
    [type='checkbox']:checked + label:before {
        content: '';
        position: absolute;
        top: 1px;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        left: 0;
    }

    [type='checkbox']:not(:checked) + label:after,
    [type='checkbox']:checked + label:after {
        position: absolute;
        content: "\e9c5";
        font-family: icomoon;
        top: 0;
        color: $white;
        font-size: 11px;
        left: 4px;
    }

    [type='checkbox']:not(:checked) + label:after {
        opacity: 0;
    }

    [type='checkbox']:checked + label:after {
        opacity: 1;
    }

    [type='checkbox']:not(:checked) + label:before {
        background-color: $white;
        border: 2px solid #DEDEDE;
    }

    [type='checkbox']:checked + label:before {
        border: 2px solid $primary;
        background-color: $primary;
    } 

    &--filter{
        @include media-breakpoint-down(md){
            label{
                background-color: $white;
                padding: rem(13) rem(15) !important;
                margin-right: rem(10) !important;
                white-space: nowrap;
                border-radius: 8px;
                box-shadow: $box-shadow;
                &::after, &::before{
                    display: none;
                }     
            }
            [type='checkbox']:checked + label {
                background: #FFD3D3;
                color: $primary;
            }
        }
    }

}

.custom-radio {
    input[type='radio'] {
        display: none;
    }
    &--inline {
        display: inline-block;
    }
    &--mright {
        margin-right: 3rem;
        @media (max-width: 575px) {
            margin-right: 1rem;
        }
    }
    &--uppercase {
        text-transform: uppercase;
    }
    &--fontweight {
        font-weight: 600;
    }
    input[type='radio'] + label {
        position: relative;
        cursor: pointer;
        font-size: rem(14);
        color: $gray-700;
        display: inline-flex !important;
        align-items: center;
        line-height: rem(20);
        margin-bottom: 0;
        padding-left: 30px;

        &:before{
            content: '';
            position: absolute;
        }
        &:before {
            width: 17px;
            height: 17px;
            border: 2px solid #a7a7a7;
            border-radius: 50%;
            background: #fff;
            left: 0;
            top: 50%;
            margin-top: -9px;
            display: block;
            transition: all ease 0.3s;
            background-clip: content-box;
            background-color: #a7a7a7;
            padding: 2px;
          }
        
    }
    input[type='radio']:checked + label {
       
        &:before {
            background-color: $primary;
            border: 1px solid $primary;
        }
    }
}
