@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import "choose-interest";
html{
  height: 100%;
}
body {
  height: 100%;
}
.login-outer{
   @include media-breakpoint-up(lg) {
    background: #F3F4F6;
    min-height:100vh ;
  }
  @include media-breakpoint-down(md){
    margin-bottom: -80px;
    background: #fff;
  }
  display: flex;
  align-items: center;
}
.login-container {
  @include media-breakpoint-up(lg) {
   
      .left-radius{
        border-radius: $border-radius-lg 0 0 $border-radius-lg ;
      }
      .right-radius{
        border-radius: 0 $border-radius-lg $border-radius-lg 0 ;
      }
      .bg-box{
        &.login{
          border-radius: 0 $border-radius-lg $border-radius-lg 0;
        }
        &.signup{
          border-radius: $border-radius-lg 0 0 $border-radius-lg ;
        }
      }
      .content-box{
        &.signup{
          border-radius: 0 $border-radius-lg $border-radius-lg 0;
        }
        &.login{
          border-radius: $border-radius-lg 0 0 $border-radius-lg ;
        }
      }
    }
  .flag-dropdown{
    border-radius:5px 0 0 5px !important;
    border: none !important;
    height: 95%;
    left: 1px;
    top:1px !important;
   }
}
.signup-form,.choose-account{
  .bg-box {
    background-position: left bottom;
  }
} 
.logo-white {
  left: 35px;
  top: 25px;
}

.bg-box {
  background: url(../assets/images/deal-bg1.png) repeat-y  #f92d2d;
  background-size: cover;
  max-width: 473px !important;
  @include media-breakpoint-down(md) {
    background: $primary;
    max-width: 100% !important;
  }
}
.left-pane {
  max-width: 473px;
}

.login-form {
  max-width: 400px;
  
   .form-control{
     border-color:$input-border-color !important;
     &:hover{
       border-color:#ff6262 !important;
     }
   }
}
.form-control.error {
  background: #ffeded;
  border-color: $primary;
}
.social-media-login {
  i,
  a {
    border-radius: 50%;
    border: $border-color 1px solid;
    background: #fff;
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s ease;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      border-color: $primary;
    }
  }
}
#country-codes {
  .form-control {
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      border-right: #bcbcbc 2px solid;
      border-bottom: #bcbcbc 2px solid;
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      margin-top: -4px;
    }
  }
  .dropdown-menu {
    min-width: 1rem;
  }
}
.country-selection {
  max-width: 90px;
}
.f-16 {
  font-size: 16px;
}
/*..........login-phone Number...........*/
.enterphone-right-box {
  @include media-breakpoint-down(lg) {
    background-color: $white;
  }
  @include media-breakpoint-up(lg) {
    background-color: $gray-100;
  }
  .icon-avatar {
    font-size: 5.3125rem;
  }
}

/*..........login-phone Number-end...........*/
/*..........otp...........*/
.otp-block {
  .p-10 {
    padding: 0 10px;
  }
  .otp-form {
    max-width: 414px;
    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }
  .icon-otp-new {
    font-size: 5.3125rem;
    @include media-breakpoint-down(md) {
      font-size: 145px;
    }
  }
  
}
/*..........otp end...........*/
/*..........chose-type accocnt...........*/
.icon-otp-img {
  font-size: 10.75rem;
}
.choose-account {
  .chose-type-block {
    ul {
      li {
        box-shadow: 1px 1px 7px 4px rgba(0, 0, 0, 0.04);
        padding: 28px 32px;
        border-radius: 7px;
        cursor: pointer;
        border: solid 2px transparent;
        @include media-breakpoint-down(md) {
          text-align: center;
          display: flex;
          flex-wrap: wrap;
          max-width: 48%;
          margin: 0 6px;
        }
        .ico {
          width: 81px;
          height: 71px;
          border: solid 1px $primary;
          border-radius: 7px;
          i {
            font-size: 2.5rem;
            &:before {
              color: $primary;
            }
          }
        }
        .txt {
          font-size: 1.125rem;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
        &.active {
          background-color: #ffe7e7 !important;
          border: solid 2px $primary;
          .ico {
            background-color: $primary;
            i {
              &:before {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.rmv-padd-sm {
  @include media-breakpoint-down(md) {
    padding: 0 !important;
    max-width: 100%;
  }
}
.top-head-common {
  h3 {
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}
.fixed-bt {
  @include media-breakpoint-down(sm) {
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 0 !important;
    bottom: 0;
  }
}
/*..........chose-type accocnt end...........*/
/*..........enter details...........*/
.account-details {
  .icon-individual {
    font-size: 77px;
  }
}
#thank-you-modal {
  .modal-content {
    border-radius: 10px;
    h3 {
      color: #212121;
      font-size: 2.25rem;
      span {
        font-size: 16px;
      }
    }
    p {
      color: #4e4d4d;
      font-size: 16px;
    }
    .icon-tick {
      font-size: 4.875rem;
      @include media-breakpoint-down(sm) {
        font-size: 55px;
        margin-top: -64px;
      }
    }
    .close {
      font-size: 16px;
      top: 25px;
      right: 20px;
    }
  }
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 45px 22px;
    }
  }
}
.login-details {
  .Input--gap-md{
    margin-bottom:0 !important;
  }
  .select__placeholder,.select__single-value{
    font-size: 14px;
  }
  .select__control{
    height:47px;
    background-color:#fafafa;
    &:focus{
      background-color:$white !important;
    }
  }
}
@include media-breakpoint-up(sm) {
  .modal-dialog {
    max-width: 470px;
    margin: 1.75rem auto;
  }
}
@include media-breakpoint-down(sm) {
  .modal-backdrop {
    background: $white;
  }
  .modal-backdrop {
    &.show {
      opacity: 0.5 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2rem !important; 
  }
  h2 {
    font-size: 20px;
  }
}
