body{
    background-color: #FFFFFF;
}
/* --------------cms banner area-------------- */
.cms-banner{    
    min-height: 859px;
}
.cms-banner .container{    
    z-index: 1;
}
.cms-banner::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    background: #EC3A3D;
    border-radius: 0 0 50% 50%/0 0 60% 60%;
    z-index: 0;
    transform: scale(1.5);
    transform-origin: bottom center;
}
.banner-right-block::before{
    content: "";
    width: 200%;
    height: 100%;
    max-height: 550px;
    background: #FD4949;
    border-radius: 50em 0 0 50em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.cms-banner h1{
    font-size: 55px;
}
.btn-opaque:hover{
    background-color: rgba(255, 255, 255, 0.1);
}
/* --------------cms banner area-------------- */

/* --------------How it works-------------- */
.circle-count{
    width: 76px;
    height: 76px;
    border: 3px solid #FD4949;
    color: #FD4949 !important;
}
.bg-light-blue{
    background-color: #F5F9FF;
}
/* --------------How it works-------------- */

/* Post item */

.post-item-box{
    box-shadow: -8px 14px 164px rgba(0, 0, 0, 0.1);
}

.post-item-list{
    font-size: 24px;
}

.post-item-list li::marker{
    color: #FD4949;
}

/* Post item */

/* Trust and Safety */

.trust-iconbox{
    width: 105px;
    height: 105px;
    background: #FFEDED;
}

.recommended-blk{
    background: #EC3A3D;
}

.trust-content{
    font-size: 24px;
    line-height: 35px;
}

/* Trust and Safety */

/* Business */

.benefits{
    background: #DFEDE7;
}

.community{
    background: #FFEDD7;
}

.messenger{
    background: #BDE5FE;
}

.listings{
    background: #D7E2FF;
}

/* Business */

/* Business */

.contact-banner{
    background: url(../assets/images/cms/contact-banner-img.png) no-repeat #EC3A3D;
    background-size: auto 100%;
}

.getintouch-block{
    box-shadow: -8px 14px 164px rgba(0, 0, 0, 0.1);
    top: -110px;
}

.getintouch-block input{
    height: 55px;
}

.lb-img-block{
    background: #EC3A3D;
    border-radius: 0 20px 20px 20px;
}

.lb-left-block{
    &::before{
        content: "";
        background: #EC3A3D;
        border-radius: 20px 0 0 0;
        width: 100%;
        height: 1rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.lb-box{
    background: #F5F9FF;
    border-left: 4px solid #EC3A3D;
    border-radius: 3px 5px 5px 3px;
    .lb-icon{
        width: 75px;
        height: 75px;
        border: 1px dashed #EC3A3D;
        img{
            max-width: 30px;
        }
        @media (max-width: 1199.98px){
            width: 50px;
            height: 50px;
            img{
                max-width: 20px;
            }
        }
    }
}

.lb-timeline{
    &::before{
        content: "";
        width: 90%;
        border-bottom: 1px dashed #EC3A3D;
        position: absolute;
        top: 37px;
        left: 0px;
        @media (max-width: 1199.98px){
            top: 25px;
        }
        @media (max-width: 575.98px){
            display: none;
        }
    }
    &.lb-timeline-one{
        &::before{
            display: none;
        }
    }
    li{
        width: 155px;
        // margin-right: 81px;
        &:first-child, &:nth-child(4){
            width: 115px;
        }
        p{
            min-width: 75px;
        }
        @media (max-width: 1199.98px){
            width: 100px;
            &:first-child, &:nth-child(4){
                width: 93px;
            }
            p{
                font-size: 12px !important;
            }
        }
        @media (max-width: 575.98px){
            width: 100% !important;
        }
    }
}

.available-block{
    background: url('../assets/images/cms/map-img.png') no-repeat #F5F9FF;
    background-position: center center;
    background-position: 70% 60%;
    .container{
        max-width: 926px;
    }
    .country-box{
        &::before{
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 18px;
            background: #EC3A3D;
            border-radius: 50%;
            opacity: 0;
            visibility: hidden;
        }
        &:hover{
            background-color: #fff;
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/* Business */

@media (max-width: 991.98px){
    .cms-banner{
        min-height: inherit;
        padding-bottom: 90px;
    }
    .cms-banner::after{   
        border-radius: 0 0 50% 50%/0 0 30% 30%;
    }
    .howitworks-block .h1{
        font-size: 2rem;
    }
}

@media (max-width: 767.98px){
    .cms-banner::before{
        height: calc(80% - 4em - 166px);
        top: inherit;
        bottom: 12%;
        width: 95%;
    }
    .feature-block h2{
        font-size: 2rem;
    }
    .getintouch-block{
        top: 0;
    }
}

@media (max-width: 575.98px){
    .cms-banner::after{   
        border-radius: 0 0 50% 50%/0 0 10% 10%;
    }
    .cms-banner::before{
        height: calc(70% - 4em - 166px);
        bottom: 5%;
    }
}