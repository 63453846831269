.Avatar {
  display: inline-block;
  &__image {
    border-radius: 50%;
    vertical-align: middle;
    object-fit: cover;
    &--small {
      width: 32px;
      height: 32px;
    }
    &--medium {
      width: 48px;
      height: 48px;
    }
    &--large {
      width: 64px;
      height: 64px;
    }
    &--xlarge {
      width: 100px;
      height: 100px;
    }
  }
  &.is-offline,
  &.is-online {
    position: relative;
    margin-right: 10px;
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      bottom: 0;
      right: 0;
    }
  }
  &.is-offline:after {
    background: #a7a7a7;
  }
  &.is-online:after {
    background: #4acc48;
  }
  &__lg {
    &.is-offline,
    &.is-online {
      &:after {
        width: 12px;
        height: 12px;
        bottom: 8%;
        right: 8%;
      }
    }
  }
}
