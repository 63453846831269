 @import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/pagination';
@import 'custom/product-box';

header{
	@include media-breakpoint-down(md){
		display: none;
	}
}

/*...left-panel...*/
.leftpanel{
	@include media-breakpoint-up(md) {
		position:sticky;
		top:100px;
	}
	 border-top-right-radius: 47px;
	 border-bottom-left-radius: 47px;
	  @include media-breakpoint-down(md){
             border-radius: 0;
		    }
 	li{
 		margin: 10px 0;
 		a{
 			padding: 13px 10px 13px 54px;
		    border-top-left-radius: 24px;
		    border-bottom-left-radius: 24px;
		    @include media-breakpoint-down(md){
             border-radius: 0;
		    }
		    &:hover{
		    	background: $primary;
		    	color: $white !important;
		    span{
		    	&:before{
		    		color: $white;
		    	}
		    }
		    }
		    span{
				left: 15px;
				top:50%;
		    	&:before{
					color: $body-color;
		    	}
				&.icon-profile {
					font-size: 20px;
					margin-top: -10px;
				}
				&.icon-my-ads{
					font-size: 17px;
					margin-top: -8px;
				}
				&.icon-auction{
					font-size: 21px;
					margin-top: -10px;
				}
				&.icon-leads{
					font-size: 21px;
					margin-top: -10px;
				}
				&.icon-my-orders,&.icon-invoice{
					font-size: 19px;
					margin-top: -9px;
				}
				&.icon-notification1 {
					font-size: 19px;
					margin-top: -9px;
				}
				&.icon-subscription  {
					font-size: 27px;
					margin-top: -9px;
				}
				&.icon-settings  {
					font-size: 20px;
					margin-top: -9px;
				}
				&.icon-support  {
					font-size: 20px;
					margin-top: -9px;
				}
			}
			&.active{
				background: $primary;
				color: $white !important;
				span{
					&:before{
						color: $white;
					}
				}
			}
 		}
 	}
 
 .fig-content{
 	figure img{
 		width: 50px;
 		height: 50px;
 	}
 }
}
.my-account-dash{
	position: sticky;
	top:0;
	@include media-breakpoint-up(xl) {
		min-width: 272px;
		max-width: 272px;
	}
}
.my-account-dash-res{
	margin-bottom: -95px;
	.icon-edit:before{
		color:$white;
	}
	.icon-close{
		position: absolute;
		right: 15px;
		top: 15px;
		font-size: 10px;
	}
}
/*...left-panel end...*/
.auction{
	.views-block{
		@include media-breakpoint-down(lg) {
			margin:0 -15px;
		}
	}
}
.right-panel{
	@include media-breakpoint-up(lg) {
		padding-left: 36px;
	}
	h2{
		font-size: rem(28);
	}
	
	.ads-item{
		@include media-breakpoint-down(lg){
			.rounded{
				border-radius: 12px !important;
			}
		}
		.inactive{
			box-shadow: none!important;
			background: #f5f5f5;
			*{
				opacity: 0.9;
			}
			.dropdown{
				opacity: 1 !important;
			}
		}
		.dropdown{
			float:right;
		}
		figure{
			padding-left:85px ;
			margin: 0;
			min-height: 70px;
			position: relative;
			@include media-breakpoint-down(md){
				&:not(.noresize){
					padding-left: 70px;
				}
				//min-height: 55px;
			}
			.image{
				width: 70px;
				height: 70px;
				border-radius: 5px;
				border:$border-color 1px solid;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left:0;
				top:0;

			   @include media-breakpoint-down(md){
                &:not(.noresize){
					width: 55px;
                	height: 55px;
				}
			   }
			   img{
				   max-width: 100%;
				   width: auto;
				   height: auto;
			   }
			}
		}
		.icon-more {
			@include media-breakpoint-down(md){
				transform: rotate(90deg);
				display: block;
				margin-top: -8px;
			}
		}
		// figcaption{
		// 	width: 82%;
		// 	@include media-breakpoint-down(md){
        //          width: 80%;
		// 	}
		// }
		
		.views-block{
			i{
				padding-right: 4px;
				line-height: 1;
				&:before{
					color: #CCCCCC;
				}
			}
		}
		.filter{
			   right: 0px;
               top:0px;
               cursor: pointer; 
			   z-index: 9;
			   @include media-breakpoint-down(md){
               	z-index: 9;
				right: 10px;
               top:18px;
			   }
               button{
               	background: transparent;
               padding: 0;
               }
		}
		.dropdown-menu{
			box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
			border:none !important;
			border-radius: 8px !important;
			right:0;
			left:inherit !important;
			padding: 10px ;
			min-width: 100px;
			// @include media-breakpoint-up(xl){
			// 	transform: translate(-120%, 40px) !important;
			// }
			.dropdown-item{
				line-height: 1;
    			font-size: 14px;
				padding:5px 10px;
			}
		}
	}
	.choose{
		.form-control{
			height: 35px;
			width: 140px;
            padding: 0 8px;

		}
	}
}
.tabs-header.myaccount{
	@include media-breakpoint-down(md){
	  background-color: $white;
	  margin:0 -15px;
	}
		.nav-item {
			font-size: 18px;
			color: $body-color;
			border: none;
			background-color: transparent;
			padding-left: 0;
			padding-right: 40px;
			border-bottom: solid 2px transparent;
			padding-right: 0;
			margin-right: 45px;
			@include media-breakpoint-down(md){
			 width: 50%;
			 margin-right: 0;
			 text-align: center;
			 font-size: 16px;
			}
			&.active{
				color: $primary;
				font-weight: $font-weight-semibold;
				border-bottom: solid 2px $primary;
				@include media-breakpoint-down(md){
				 color: $black;
				 background-color: $white;
				}
			}
		}
	   .count{
		   font-size: 14px;
	   }
}







///////////////////////////

// invoice

///////////////////////////


.invoice-pannel{
	&-boxes{
		background-color: #FFD3D3;
	}
	@include media-breakpoint-down(sm) { 
		.width-row{
			overflow: auto;
			white-space: nowrap;
			display: flex;
			flex-wrap: nowrap;
		}
		.panel-box1{
			max-width: 175px;
		}
	}
	&-search{
		.search-fields{
			.form-control{
				height: 35px !important;
				padding: 9px;
			}
			.first-width{
				width: 140px;
			}
			.second-width{
				width: 210px;
			}
			.datefield{
				.date-in {
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					height: 100%;
					align-items: center;
					padding: 0 10px;
					font-size: 16px;
				}
				.form-control{
					padding-right: 32px;
				}
			}
			.search-btn{
				padding: 5px 15px;
				font-size: 14px;
			          }
		          }
		     }
	&-listing{
		&.my-enquiry{
			border:1px solid #E5E5E5;
			overflow: hidden;
		}
		.invoice-heads{
			border: 1px solid #ECECEC;
			padding: 10px 15px;
			margin-bottom: 8px;
		}
		.enquiry-heads{
			padding:15px;
		}
		.enquiry-row{
			padding:20px 15px;
			border-bottom:1px solid #ECECEC;
			&:last-child{
				border-bottom:none;
			}
			.enq-id{
				span{
					color:#420610;
					background-color:#E9C7CD;
					width:32px;
					height:32px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
				}
			}
			.enq-data{

			}
		}
		.invoice-row {
			padding: 27px 15px;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
			margin-bottom: 12px;
			@include media-breakpoint-down(sm) { 
				padding: 20px 15px;
				border-radius: 12px !important;
				p{
					word-break: break-all;
				}
				.labelin{
					color: $input-placeholder-color;
					font-size: 13px;
					padding-bottom: 2px;
				}
				.price{
					h6{
						color: $gray-900;
					}
				}
				.status{
					span{
						background-color: transparent;
						color: $gray-700 !important;
						font-size: 14px !important;
    					padding: 0 !important;
					}
				}
			}
			.inv-id {
				span{
				padding-right: 5px;
				i{
					font-size: 17px;
				}
				}
			}
			
		}
	}
}
.invoice-table{
	@include media-breakpoint-up(md) {
		.inv-table-id-2{
			display: none;
		}
	
	 }
	
	@media (max-width: 767.98px) { 
		.inv-table-id{
			display: none;
		}
		margin: 0 -15px;
		padding: 0px 5px;
	h5{
		font-size: 16px;
	}
	p{
		font-size: 14px !important;
	}
	table{
		th , td{
		font-size: 14px !important;
		}
	}
}
}


///////////////////////////

// saved cards

///////////////////////////

                
.saved-cards-inner{
	.add-new{
		border: 1px solid transparent;
		border-color: $primary !important;
	}
	&-listing{
		.saved-card{
			padding: 20px;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
			margin-bottom: 15px;
			@include media-breakpoint-down(md) { 
				border-radius: 12px !important;
			}
			.card-number{
				span{
					color: #CECECE;
					margin-right: 35px;
				}
				.last-digits{
					color: $gray-900 !important;
					font-size: 16px !important;
					margin: 0;
				}
			}
			.more-in-cards{
				right: 20px;
				top: 20px;
				z-index: 9;
				.saved-cards-more {
					button{
						border: none;
						&:focus{
							border: none;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
}
///////////////////////////

// insight

///////////////////////////

.insight-inner{
	.insight-box{
		.ins-box{
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
			transition: .3s;
			&:hover{
				background-color: $primary !important;
				color: $white !important;
				transition: .3s;
				h4{
					color: $white !important;
				}
			}
			@include media-breakpoint-down(sm) { 
				h4{
					font-size: 22px;
				}
				p{
					font-size: 14px !important;
				}
			}
			
		}
	}
}

///////////////////////////

// settings

///////////////////////////

.settings-inner{
	.settings-fill-tabs{
		@include media-breakpoint-down(md) { 
		.tab-pane{
			display: block;
			opacity: 1;
			border-radius: 0 !important;
			border-bottom: 1px solid #EFEFEF;
		}
		.tab-content{
			margin: 0 -15px;
			padding: 5px 0;
		}
		.notifications{
				h6{
					font-size: 16px !important;
			     }
				.up-text{
					font-size: 14px !important;
				}
		}
	}


		.switching-checkbox{
		input[type=checkbox]{
			height: 0;
			width: 0;
			visibility: hidden;
			display: block;
		}
		
		label {
			cursor: pointer;
			text-indent: -9999px;
			width: 42px;
			height: 10px;
			border: $gray-500 2px solid;
			display: block;
			border-radius: 100px;
			position: relative;
			margin: 0;
			background-color:$white;
		}
		
		label:after {
			content: '';
			position: absolute;
			top: -8px;
			left: -2px;
			width: 23px;
			height: 23px;
			background: $gray-500;
			border-radius: 50%;
			transition: 0.3s;
			border: 3px solid $white;
		}
		
		input:checked + label{
			border: $body-color 2px solid;
			&:after {
				left: calc(100% - -2px);
				transform: translateX(-100%);
				background: $primary;
			}
		}
		
		label:active:after {
			width: 30px;
		}
	}
	}
}
.ReactModal__Body--open{
	@include media-breakpoint-down(sm) {
		.modal-content{
			border: none;
    		border-radius: 0;
		}
		.ReactModal__Overlay{
			background-color:$white !important;
		}
		.ReactModal__Content{
			height: 100%;
			border-radius: 0 !important;
			inset: 0 !important;
			border: 0 !important;
			margin:0 !important;
		} 
	}
}
///////////////////////////

// notification

///////////////////////////

.notification-inner{
	@include media-breakpoint-down(md) { 
		margin:0 -15px;
	}
	.notification-box{
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
		padding: 15px 20px;
		margin-bottom: 10px;
		@include media-breakpoint-down(md) { 
			border-radius: 0 !important;
			box-shadow: none;
			border-bottom: 1px solid $input-border-color;
			padding: 15px 15px;
			margin-bottom: 0;
		}

	}
	.avatar-in{
		min-width: 45px;
		max-width: 45px;
		margin-right: 20px;
		border-radius: 50%;
		img{
			width: 45px;
			height: 45px;
			object-fit: cover;
			border-radius: 50%;
		}
	}
	.notification-text{
		p{
		   @include media-breakpoint-down(sm) { 
			font-size: 14px !important;
		   }
		}
	}
	.more {
		border: none;
		box-shadow: none;
		i{
			color: $text-muted;
		}
		&:focus{
			border: none;
			box-shadow: none;
		}
	}
}

///////////////////////////

// Profile edit

///////////////////////////

.profile-pic{
	max-width: 114px;
	width:100%;
	height: 114px;
	img{
		width:100%;
		height:100%;
	}
	.upld{
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border:2px solid $white;
		position: absolute;
		bottom: 3px;
		right: 5px;
		i{
			font-size: 10px;
		}
	}
	.uploading-group-inn{
		border: 0 !important;
		position: relative;
		&:hover{
			img{
				transform: translateY(7px);
			}
		}
		img{
			padding: 0 !important;
		}
		.upld{
			display: flex !important;
		}
		p{
			display: none;
		}
	}
}
.profile-fill{
	.profile-detail{
		@include media-breakpoint-down(md) {
			margin-top: 65px;
			text-align: center;
		}
		.icon-fb{
			&:before{
				color: #c4c4c4;
			}
			&.active{
				&:before{
					color:#3a5a99;
				}
			}
		}
		.icon-gplus{
			&.active{
				.path1:before{
					color:$primary;
				}
			}
		}
		.icon-phone2{
			&.active{
				.path1:before{
					color:#f3a340;
				}
			}
		}
		.icon-mail2{
			&.active{
				.path1:before{
					color:#2e8fc7;
				}
			}
		}
	}
	.profile-pic{
		@include media-breakpoint-down(md) { 
			background:$primary;
			max-width: 100%;
			text-align: center;
			height: 132px;
			img{
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				top: 65px;
				width: 114px;
    			height: 114px;
			}
		}
	}
	.name-rating{
		h4{
			text-transform: capitalize;
			@include media-breakpoint-up(lg) { 
				color:$primary;
			}
			@include media-breakpoint-down(lg) {
				font-size:22px;
			}
		}
	}
	.tab-content{
		@include media-breakpoint-down(lg) {
			background-color:$body-bg;
			padding:0 1px;
			.tab-pane>.row{
			  margin: 0!important;
			  >[class^='col']{
				 padding:0 2px!important ;
			  }
			  .productBox{
				  margin-bottom: 4px!important;
			  }
			}	
		}
	}
}
.edit-profile-inner{
  .edit-profile-fill{
	@include media-breakpoint-down(md) {
		padding-bottom:35px;
	}
	.country-selection {
		max-width: 90px;
	}
	#country-codes{
		.form-control{
			&:after{
				content: "";
				position: absolute;
				right: 15px;
				top:50%;
				border-right: #bcbcbc 2px solid;
				border-bottom: #bcbcbc 2px solid;
				transform: rotate(45deg);
				width: 8px;
				height: 8px;
				margin-top: -4px;
				
			}
		}
		.dropdown-menu{
		  min-width: 1rem;  
		}   
	}
	.profile-edit-form{
		@include media-breakpoint-down(sm) { 
		.form-control,.Input__field,.select__control {
			border-radius: 0 !important;
			border-top: none;
			border-left: none;
			border-right: none;
			background-color: transparent !important;
			padding: 5px 0px;
			height: auto;
			&:focus{
			border-top: none;
			border-left: none;
			border-right: none;
			box-shadow: none;
			}
		}
		.select__value-container{
			padding:0;
		}
		.css-tlfecz-indicatorContainer{
			padding: 0 8px;
		}
		label{
			color: $text-muted;
		}
	}
	}
	.pswd-btn {
		height: 35px;
		&:hover{
			background-color:$primary !important;
		}
	}
}
}
.profile-edit{
	@include media-breakpoint-down(md) { 
		margin-bottom:-80px;
		position: relative;
		z-index: 999;
		background: #fafafa;
		.profile-edit-save{
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 0;
		}
	}
}
.icon-settings.text-white{
	&:before{
	  color: #fff;
	}
  }

///////////////////////////

// Profile 

///////////////////////////
.profile-header{
	@include media-breakpoint-down(md) {
		background-color:$body-bg; 
	}
	.ratings{
		.star{
		color: #FFCD3D;
	  	 }
		.star-outer{
			color: #C4C4C4;
		}
	}
	.social{
		ul{
			li{
			margin-right: 2px;
			line-height: 1;
			}
		}
		.verify-text{
			@include media-breakpoint-up(lg) {
				font-size:1rem;
			}
			@include media-breakpoint-down(lg) {
				font-size:13px;
			}
		}
	}
	
}

.update-phone{
	max-width: 500px;
	@include media-breakpoint-up(lg) {
	box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
	margin: rem(50) auto;
	}
	.icon-avatar{
		display: none !important;
	}
}

// tab
.tab-profile {
	.tab-hed {
	  @include media-breakpoint-down(md) {
		margin: 0px;
		background-color: $body-bg;
    	
	  }
	  ul {
		@include media-breakpoint-down(md) {
		  display: flex;
		  justify-content: space-around;
		  background-color:$white;
		  border-top: 1px solid #fefefe;
		}
		li {
		  margin-right: 48px;
		  @include media-breakpoint-down(md) {
			flex-basis: 0;
			flex-grow: 1;
			text-align: center;
			margin-right: 0;
		  }
		  .nav-link {
			padding: 0;
			border: none;
			font-size: rem(18);
			color: $text-muted;
			padding: 10px 0px;
			font-weight: 500;
			@include media-breakpoint-down(md) {
				font-size: 14px;
				line-height: 1;
				height: 62px;
				display: flex;
				align-items: flex-end;
				justify-content: center;
			}
			&.active {
			  background-color: transparent;
			  border: none;
			  color: $dark;
			  border-bottom: 2px solid $primary;
			}
		  }
		  &:last-child {
			margin-right: 0;
		  }
		}
	  }
	}
  }
  // tab

//   payout popup

.modal-big{
	width: 100% !important;
	max-width: 1200px !important;
}

.payout-item{
	max-width: 196px;
	@include media-breakpoint-down(xs) {
		max-width: 96px;
	}
}

.payout-item-scroll{
	max-height: 80vh;
	overflow: auto;
}

.subscription-box {
	background: url(../assets/images/deal-bg1.png) repeat-y left top $primary;
	max-width: 473px;
	box-shadow: 4px 4px 20px rgba(253, 50, 50, 0.4);
	min-height: 163px;
	h3{
		font-size:23px;
	}
}
.account-selection{
	&.selected{
		background: #FAFAFA;
	}
}
.sticky-element{
	z-index: 10;
	background: rgba($white,.2);
.productBox_auction{
	p{
		@include media-breakpoint-down(md) {
			font-size:13px !important;
			margin-bottom: 10px;
		}
	}
	&--action{
		.btn{
			height: 30px;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@include media-breakpoint-down(md) {
			span{
				font-size:13px !important;
			}
		}
	} 
}
}
.modal-dialog{
	&.md{
		max-width: 682px;
	}
}	
.my-ads{ 
	.mob-package-block{
		background-color: #FFD3D3; 
		border-radius: 8px;
		.btn{
			height: 48px;
			display: flex;
			align-items: center;
			font-size: 15px !important;
			justify-content: center;
		}
	}
}

.border-bottom-lg-0{
	@include media-breakpoint-up(lg) {
		border-bottom:none !important;
	} 
	@include media-breakpoint-down(lg) {
		border-bottom:1px solid $gray-300;
	}
}
.dropdown.filter{
	&.open{
		.dropdown-menu{
			display: block;
			right: 14px;
			left: auto;
			top: 0;
			margin: 0;
		}
	}
}
.status{
	padding: 3px 10px;
	font-size: 11px;
	border-radius: 10px;
	text-transform: capitalize;
	font-weight: $font-weight-semibold;
	cursor: pointer;
	&.active {
		background-color: rgba(1, 161, 183,0.2);
		color: #01A1B7;
	  }
	  &.cancelled{
		background-color: rgba(#E10000, .2);
		color: #E10000;
	  }
	  &.inactive {
		background-color: rgba(#4b4a4a, .2);
		color:#4b4a4a;
	  }
	  &.pending{
		background-color:rgba(#ECCA1A, .2);
		color:#968908;
	  }
	  &.completed{
		background-color:rgba(#01B753, .2);
		color: #01B753;
	  }
	 
}

.search-field{
	.select__control {
		height: 41px!important;
		&:focus,&:focus-within{
			border-color: #ff6262!important;
		}
	}
	.input-group{
		border:1px solid $border-color;
		border-radius: .2rem;
		input,.btn{
			border:0;
		}
		&:focus-within{
			border-color: #ff6262;
    		outline: 0;
    		//box-shadow: 0 0 0 1px rgba(225, 0, 0,0.75);
			input,.btn{
				box-shadow:none;
			}
		}
	}
}
.open-filter{
	@include media-breakpoint-down(md){
		overflow: hidden;
	}
}
.filter-toggle{
	@include media-breakpoint-up(lg){
		display: none;
		&.active{
			display: block;
		}	
	}
	@include media-breakpoint-down(md){
		position: fixed!important;
		left:110%;
		transition: all ease .5s;
		z-index: 1010;
		top:0;
		bottom:0;
		height: auto;
		transition: all ease .5s;
		width: 100%;
		padding: 0;
		overflow: auto; 
		background-color: #fff;
		box-shadow: 0 5px 5px rgba(0,0,0,0.1);
		border-radius: 0;
		padding-bottom:40px;
		.open-filter &{
			left:0;
		}
	}
	.select__control{
		padding-top: 0;
		padding-bottom: 0;
		height:39px !important;
	}
	.form-control{
		height:39px !important;
		padding-top: 0;
		padding-bottom: 0;
		line-height: 39px;
		border-radius: 0.2rem;	
	}

	
	
	.filter-btn{
		height: 39px;
		.btn{
			height: 39px;
			line-height: 39px;
		}
		@include media-breakpoint-down(md){
			position: fixed;
			bottom: 0;
			left:110%;
			transition: all ease .5s;
			right: 0;
			width: 100% !important;
			background: #fff;
			padding:0;
			height: 45px;
			z-index: 1000;
			box-shadow: 0 -1px 3px rgba(0,0,0,0.1);
			.open-filter &{
				left:0;
			}
			.row{
				flex-direction:row-reverse;
				margin: 0 -5px!important;
			}
			.btn{
				width: 100%;
				height: 45px;
				line-height: 45px;
				border-radius: 0!important;
			}
		}
    
	}
}
.top-head-block{
	h3{
		@include media-breakpoint-down(md){
			font-size:rem(22);
		}
	}
}
.qr-code{
	*{
		max-width: 50px;
		max-height: 50px;
	}
}
.new-add{
	h5{
		@include media-breakpoint-up(lg){
			font-size:rem(24)
		}
	}
	.icon-close{
		position: absolute;
		right: 21px;
		top: 23px;
	}
	.add-new-btn{
		width:147px;
		height: 46px;
	}
}
.follow-head{
	font-size:rem(26);
}
.follower-list{
	figure{
		width:45px;
		height: 45px;
	}
	.btn{
		width:85px;
		height:31px;
		&:hover{
			color:$secondary;
			background-color:$primary !important;
		}
	}
}
.right-icons{
	font-size: 20px;
}
.pic-content{
	.avatar {
		width: 60px;
		height: 60px;
		min-width: 60px;
		max-width: 60px;
		margin-right: 25px;
		position: relative;
		overflow: visible;
		.avatar-main {
		  width: 60px;
		  height: 60px;
		  object-fit: cover;
		  border-radius: 4px;
		  background-color: lighten($primary,50%);
		}
		.avatar-sub {
		  width: 30px;
		  height: 30px;
		  overflow: hidden;
		  border-radius: 50%;
		  object-fit: cover;
		  position: absolute;
		  bottom: -7px;
		  right: -12px;
		  background-color: lighten($primary,50%);
		  img{
			width: 100%;
			height: 100%;
		  }
		}
	  }
}
