@import '../../../styles/custom-variable/variables';

.RadioSelect {
  display: inline-block;
  padding: 15px 0;
  padding-right: 30px;
  &:last-child {
    padding-right: 0;
  }
  &--sm-gap {
    padding-right: 10px;
  }
  &--inline {
    display: inline-block;
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label {
    padding-left: 32px;
    position: relative;
    cursor: pointer;
    margin: 0;
    &:before{
      content: '';
      position: absolute;
    }
    &:before {
      width: 19px;
      height: 19px;
      border: 2px solid #a7a7a7;
      border-radius: 50%;
      background: #fff;
      left: 0;
      top: 50%;
      margin-top: -9px;
      display: block;
      transition: all ease 0.3s;
      background-clip: content-box;
      background-color: #a7a7a7;
      padding: 1px;
    }
    
  }
  input[type='radio']:checked + label {
    color: $gray-900;
    &:before {
      border-color: $primary;
      background-color: $primary;
    }
  }
  input[type='radio']:not(:checked) + label {
    color: $gray-700;
  }
  .button-type {
    border: 2px solid $border-color;
    padding: rem(17) !important;
    min-width: rem(152);
    border-radius: 4px;
    text-align: center;
    margin-bottom: 9px !important;
    &:before,
    &:after {
      display: none !important;
    }
  }

  input[type='radio']:checked + .button-type {
    border-color: $primary;
  }
}
