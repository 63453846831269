@import 'custom-variable/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'custom/banner';
@import 'custom/product-box';
@import 'custom/checkbox-radio';

.filter-outer {
  @include media-breakpoint-down(md) {
    overflow: auto;
    &::after {
      content: '';
      min-width: 10px;
      min-height: 1px;
    }
  }
}

.filter-tag {
  border-color: #dedede !important;
  i {
    font-size: 6px;
  }
}

.custom-dropdown {
  &--open {
    .custom-dropdown__menu {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  &__menu {
    transform: scale(0.8);
    transform-origin: top;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    a {
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

.filter-clear-all {
  i {
    font-size: 6px;
  }
}

.filter-toggle-body {
  &--colapsed {
    display: none;
  }
}

header {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.filter-section {
  @include media-breakpoint-down(md) {
    position: fixed!important;
    left:110%;
    top: 0;
    height: 100%;
    z-index: 900;
    padding: 0!important;
    background-color: $gray-100;
    overflow: auto;
    transition: all 0.3s ease-in-out;
    .open-filter & {
      left: 0;
    }
    .filter-toggle-box {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }
}
.open-filter,.open-search{
  @include media-breakpoint-down(md) {
    overflow: hidden;
    .switch-view{
      display: none!important;
    }
  }
}

.filter-checkbox {
  @include media-breakpoint-down(md) {
    display: flex;
    overflow: auto;
  }
}

.ui-slider {
  height: 2px !important;
  background-color: #e6e6e6 !important;
  border: 0 !important;
  margin-bottom: 20px;
  margin-top: 15px;
  .ui-slider-handle {
    width: 23px !important;
    height: 23px !important;
    border-radius: 50%;
    border: 1px solid #f0f0f0 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    background-color: $white !important;
    top: 50% !important;
    transform: translateY(-50%);
    position: relative;
    &:focus {
      outline: 0;
    }
    @include media-breakpoint-down(md) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &::before {
        content: '';
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: $primary;
      }
    }
  }
  .ui-slider-range {
    background-color: $primary;
  }
}

.rc-slider {
  .rc-slider-handle {
    background-color: $primary;
    border-color: $primary;
    &-dragging {
      box-shadow: 0px 0 0 5px rgba($primary, 0.5) !important;
      border-color: rgba($primary, 0.5) !important;
      &:focus {
        outline: 0;
      }
    }
  }
  .rc-slider-track {
    background-color: $primary;
  }
}
.switch-view {
  @include media-breakpoint-up(lg) {
    border: $border-color 1px solid;
    background-color: $white;
    border-radius: 6px;
    font-size: 14px;
    color: $gray-900;
    padding:8px;
    align-items: baseline;
    i{
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    width: 44px;
    height: 44px;
    bottom: 75px;
    right: 15px;
    background: $primary!important;
    color:$white!important;
    font-size: 18px;
    position: fixed;
    z-index: 10000;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    i{
      font-size: 18px;
    }
  }
}
#listing-map-view{
  height: 700px;
}
.map-pointer-listing{
  border-radius: 500rem;
  display: inline-block;
  box-shadow: 0 10px 10px rgba(0, 0, 0 ,0.2);

  &:after{
    content: '';
    width: 10px;
    height: 10px;
    background: $white;
    position: absolute;
    bottom: -3px;
    transform: rotate(45deg);
    display: inline-block;
    left: 50%;
    margin-left: -5px;
  }
  &.active{
    z-index: 100;
    &:after{
      background: $primary;
    }
  }
}
.card.info-window{
  border-radius: 12px;
  @include media-breakpoint-up(md) {
    width: 500px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.card-body{
  position: relative;
  @include media-breakpoint-up(md) {
    padding-left: 160px;
    min-height: 140px;
  }
  @include media-breakpoint-down(sm) {
    padding-left:90px;
    min-height: 80px;
  }
  .imageblock{
    position: absolute;
    left:0;
    top:0;
    background-color: $gray-200;
    display: flex;
    align-items: center;
    overflow: hidden;
    
    @include media-breakpoint-up(md) {
      width:140px;
      max-height: 140px;
      border-radius: 10px;
    }
    @include media-breakpoint-down(sm) {
      width: 80px;
      max-height: 80px;
      border-radius:5px;
    }
  }
  .card-titile{
    font-size: rem(22)!important;
  }
  
}
.listingpage .gm-style {
  @include media-breakpoint-down(md) {
    position: fixed!important;
    top: 50px!important;
  }
}
.open-map{
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}
.product-list-outer{
  @include media-breakpoint-down(sm){
    margin: 0 -12px;
    .gutter{
      &-5 {
        margin-left: -2px !important;
        margin-right: -2px !important;
    
        & > [class^='col'] {
          padding-left: 2px !important;
          padding-right: 2px !important;
        }
      }
    }
    .productBox{
      margin-bottom: 4px;
      border-radius: 0!important;
      figure{
        border-radius: 0!important;
      }
    }
  }
}
